import React from "react";

const Percent = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#FA5961",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="167848"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 64a448 448 0 1 0 0 896A448 448 0 0 0 512 64z m0 820.032A372.032 372.032 0 0 1 512 139.968a372.032 372.032 0 0 1 0 744.064z"
      fill={color}
      p-id="167849"
    ></path>
    <path
      d="M529.856 793.6v-54.144c38.144-2.496 68.928-11.712 93.504-27.712 32-22.144 48.64-54.784 48.64-98.432 0-44.352-21.568-78.144-64-102.144-6.144-2.496-32.64-12.928-78.144-30.208V340.032c16.64 1.92 30.144 6.784 40.576 14.784 15.36 11.072 25.28 30.144 28.928 56.64h65.28c-6.208-44.288-21.568-76.928-46.784-96.64-22.144-17.856-51.072-28.288-88-30.72V233.6h-38.144v50.432c-35.712 1.92-64.64 11.136-88 28.352-30.784 21.504-46.144 52.288-46.144 92.288 0 45.568 21.504 79.36 64.64 102.144l69.504 25.856v150.144c-20.928-2.432-36.928-9.216-48-20.288-14.784-14.144-22.784-38.144-25.28-71.36H352c3.712 50.432 17.856 87.36 43.712 112 22.144 21.504 54.144 33.792 96 36.288v54.144h38.144z m-38.144-326.784c-1.28 0-1.92-0.64-2.496-0.64-43.072-16-64.64-36.288-64.64-61.44 0-23.424 8.064-40.704 25.28-51.776 10.432-7.36 24.576-12.288 41.856-13.504v127.36z m38.144 216V546.24c3.712 1.216 7.36 3.072 11.072 4.288 43.072 14.72 64.64 36.928 64.64 65.28a59.008 59.008 0 0 1-26.496 50.432 116.48 116.48 0 0 1-49.28 16.64z"
      fill={color}
      p-id="167850"
    ></path>
  </svg>
);

export default Percent;
