import React, { Component } from "react";
import "../style/category.scss";
// import Pagination from "@material-ui/lab/Pagination";
import AddUserIcon from "../icon/AddUserIcon";
import DeleteIcon from "../icon/DeleteIcon";
import EditIcon from "../icon/EditIcon";
import * as GetCategoryListAPI from "../apis/GetCategoryListAPI";
import Loading from "./common/Loading";
import NoContent from "../icon/NoContent";
import * as DeleteCategoryAPI from "../apis/DeleteCategoryAPI";
import { Pagination } from "antd";
import CountryIcon from "../icon/Country";
import { FlagIcon } from "react-flag-kit";
import AddCategory from "../icon/AddCategory";

import moment from "moment";
import cookie from "react-cookies";
import { setAPIAuthorizationToken } from "../apis/API";
import Item from "antd/es/list/Item";
import AlertBox from "./common/AlertBox";
import { routePage } from "../utils/RoutePage";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      page: 1,
      per_page: 10,
      count: 0,
      userList: [],
      deleteId: "",
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.getCategoryList = this.getCategoryList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePreviewAlert = this.handlePreviewAlert.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.confirmHandler = this.confirmHandler.bind(this);
  }

  alertRef = React.createRef();

  componentDidMount() {
    let param = cookie.load("token");
    setAPIAuthorizationToken(param.token);
    this.getCategoryList(this.state.page, this.state.per_page);
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getCategoryList(page, per_page) {
    this.toggleShow();
    GetCategoryListAPI.getCategoryList(page, per_page)
      .then((res) => {
        this.toggleShow();
        var data = res.data.data;
        if (data) {
          var total = data.total;
          console.log(data.list);
          let count = Math.ceil(total / per_page);
          this.setState({
            userList: data.list,
            count,
          });
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  handlePageChange(e, page, pageSize) {
    // 确保 page 和 pageSize 是数字
    const currentPage = parseInt(page, 10);
    const currentPerPage = parseInt(pageSize, 10);

    if (isNaN(currentPage) || isNaN(currentPerPage)) {
      console.error("Invalid page or pageSize");
      return;
    }
    this.setState({ page: currentPage, per_page: currentPerPage }, () => {
      this.getCategoryList(currentPage, currentPerPage);
    });
  }

  handlePreviewAlert(id) {
    this.handleToggle();
    this.setState({ deleteId: id });
  }

  handleToggle() {
    if (this.alertRef) {
      this.alertRef.current.toggleAlertShow();
    }
  }

  confirmHandler() {
    this.toggleShow();
    DeleteCategoryAPI.deleteCategory(this.state.deleteId)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        if (code === 1) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Delete category successfully ",
            })
          );
          this.getCategoryList(this.state.page, this.state.per_page);
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  render() {
    const { count, page, userList } = this.state;
    let { history } = this.props;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <AlertBox
          ref={this.alertRef}
          {...this.props}
          confirmHandler={this.confirmHandler}
          toggleAlertShow={this.handleToggle}
        />
        <div className="category-toolbar">
          <div
            className="logo"
            onClick={() => routePage(history, "AddCategory")}
          >
            <AddCategory size={24} color="#fff" className="icon" />
          </div>
        </div>
        <div className="category-page">
          <div className="head">
            <div className="left">
              <div className="name">CATEGORY</div>
              <div className="currency">CURRENCY</div>
              <div className="price">PRICE</div>
              <div className="type">TYPE</div>
              <div className="state">STATE</div>
              <div className="time">TIME</div>
            </div>
            <div className="tool">OPERATION</div>
          </div>
          <div className="body">
            {userList.length > 0 ? (
              <React.Fragment>
                {userList.map((item, index) => {
                  return (
                    <React.Fragment>
                      <div className="li" key={index}>
                        <div className="left">
                          <div className="name">{item.menuname}</div>
                          <div className="currency">{item.currency}</div>
                          <div className="price">{item.menuprice}</div>
                          <div className="type">{item.type}</div>
                          <div
                            className={`state ${
                              item.status > 0 ? "on" : "off"
                            }`}
                          >
                            {item.status > 0 ? "ON" : "OFF"}
                          </div>
                          <div className="time">
                            {moment(item.createtime * 1000).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </div>
                        </div>
                        <div className="tool">
                          <EditIcon
                            size={25}
                            className="icon"
                            color="#285db1"
                            onClick={() =>
                              routePage(history, "AddCategory", item.id)
                            }
                          />
                          <DeleteIcon
                            size={25}
                            className="icon"
                            color="#285db1"
                            onClick={() => this.handlePreviewAlert(item.id)}
                          />
                        </div>
                      </div>
                      <div className="mobile-li">
                        <div className="row">
                          <div className="right">
                            <div className="r-row">
                              <div className="title">NAME</div> <i>:</i>
                              <div className="value">{item.menuname}</div>
                            </div>
                            <div className="r-row">
                              <div className="title">TYPE</div>
                              <i>:</i>
                              <div className="value type">{item.type}</div>
                            </div>
                            <div className="r-row">
                              <div className="title">PRICE</div>
                              <i>:</i>
                              <div className="value">USD {item.menuprice}</div>
                            </div>
                            <div className="r-row">
                              <div className="title">TIME</div>
                              <i>:</i>
                              <div className="value">
                                {moment(item.createtime * 1000).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </div>
                            </div>
                            <div className="r-row">
                              <div className="title">STATE </div>
                              <i>:</i>
                              <div className="value">
                                <div
                                  className={`state ${
                                    item.status > 0 ? "on" : "off"
                                  }`}
                                >
                                  {item.status > 0 ? "ON" : "OFF"}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="btn">
                            <EditIcon
                              size={25}
                              className="icon"
                              color="#285db1"
                              onClick={() =>
                                routePage(history, "AddCategory", item.id)
                              }
                            />
                            <DeleteIcon
                              size={25}
                              className="icon"
                              color="#285db1"
                              onClick={() => this.handlePreviewAlert(item.id)}
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            ) : (
              <div className="no-content">
                <NoContent size={200} />
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <Pagination
            size="small"
            total={this.state.count * this.state.per_page}
            // count={count}
            pageSize={this.state.per_page}
            current={this.state.page}
            onChange={(page, pageSize) =>
              this.handlePageChange(null, page, pageSize)
            }
            showQuickJumper
            variant="outlined"
            color="standard"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Category;
