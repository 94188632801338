import React, { Component } from "react";
import CloseIcon from "../icon/CloseIcon";
import "../style/addSku.scss";
import Loading from "./common/Loading";
import Submit from "../icon/SubmitIcon";
import * as SubmitSkuAPI from "../apis/SubmitSkuAPI";
import * as GetSkuAPI from "../apis/GetSkuAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";

import FileIcon from "../icon/FileIcon";
import { upload } from "../utils/UploadFile";
import cookie from "react-cookies";
import { img } from "@douyinfe/semi-ui/lib/es/markdownRender/components";
import { Select } from "antd";
import { setAPIAuthorizationToken } from "../apis/API";
import EditorMix from "./common/EditorMix";
import { getId } from "../utils/RoutePage";

class AddSku extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      name: "",
      sell: "",
      purchase: "",
      image: null,
      currency: "USD",
      currencyList: [
        {
          value: "USD",
          label: "USD",
        },
        {
          value: "JPY",
          label: "JPY",
        },
        {
          value: "EUR",
          label: "EUR",
        },
        {
          value: "GBP",
          label: "GBP",
        },
      ],
      skuDetail: "",
      policy: "",
      request: "",
      staffRequest: "",
      addContent: "",
      id: 0,
      isClick: true,
      status: 1,
    };
    this.inputChange = this.inputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.back = this.back.bind(this);
    this.clickToMove = this.clickToMove.bind(this);
  }

  skuDetailRef = React.createRef();
  policyRef = React.createRef();

  componentDidMount() {
    let param = cookie.load("token");
    setAPIAuthorizationToken(param.token);
    let { location } = this.props;
    let id = getId(location, "AddSku");
    if (id) {
      this.toggleShow();
      GetSkuAPI.getSku(id)
        .then((res) => {
          this.toggleShow();
          console.log(res);
          var data = res.data.data;
          if (data) {
            this.setState({
              id: parseInt(id),
              name: data.name,
              sell: data.price,
              purchase: data.costprice,
              image: data.icon,
              currency: data.currency,
              status: data.status,
              addContent: data.fujiawenzi,
              request: data.xuqiumoban,
              staffRequest: data.yuangongxuqiumoban,
              skuDetail: data.chanpinxiangqing,
              policy: data.shouhouzhence,
              isClick: data.status > 0 ? true : false,
            });
          }
        })
        .catch((err) => {
          this.toggleShow();
        });
    }
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  async inputChange(e, type) {
    let param = cookie.load("token");
    switch (type) {
      case "name":
        this.setState({ name: e.target.value });
        break;
      case "sell":
        this.setState({ sell: e.target.value });
        break;
      case "purchase":
        this.setState({ purchase: e.target.value });
        break;
      case "image":
        const url = await upload(e, param.token);
        if (url) {
          this.setState({ image: url });
        }
        break;
      case "currency":
        this.setState({ currency: e });
        break;
      case "request":
        this.setState({ request: e.target.value });
        break;
      case "staffRequest":
        this.setState({ staffRequest: e.target.value });
        break;
      case "add":
        this.setState({ addContent: e.target.value });
        break;
      default:
        break;
    }
  }

  clickToMove() {
    let { isClick, status } = this.state;
    status = isClick ? 0 : 1;
    this.setState({ isClick: !this.state.isClick, status });
  }

  submit() {
    let {
      id,
      name,
      sell,
      purchase,
      image,
      currency,
      status,
      request,
      policy,
      skuDetail,
      addContent,
      staffRequest,
    } = this.state;
    this.toggleShow();

    skuDetail = this.skuDetailRef
      ? this.skuDetailRef.current.getValue()
      : skuDetail;

    policy = this.policyRef ? this.policyRef.current.getValue() : policy;

    let param = {
      id,
      name,
      price: sell,
      costprice: purchase,
      icon: image,
      currency,
      status,
      fujiawenzi: addContent,
      xuqiumoban: request,
      chanpinxiangqing: skuDetail,
      shouhouzhence: policy,
      yuangongxuqiumoban: staffRequest,
    };
    SubmitSkuAPI.submitSku(param)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        console.log(msg);
        if (code === 1) {
          if (id !== 0) {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "Modify Sku successfully",
              })
            );
            this.back();
          } else {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "Add Sku Successfully",
              })
            );
            this.back();
          }
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      name,
      sell,
      purchase,
      image,
      currencyList,
      currency,
      isClick,
      skuDetail,
      request,
      policy,
      addContent,
      staffRequest,
    } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="addSku-page">
          <CloseIcon
            size={30}
            className="icon close"
            color="#5864ff"
            onClick={this.back}
          />
          <div className="row">
            <div className="title">Sku name :</div>
            <input
              type="text"
              value={name || ""}
              onChange={(e) => this.inputChange(e, "name")}
            />
          </div>
          <div className="row">
            <div className="title">Sell Price :</div>
            <input
              type="number"
              min={0}
              value={sell || ""}
              onChange={(e) => this.inputChange(e, "sell")}
            />
          </div>
          <div className="row">
            <div className="title">Purchase Price:</div>
            <input
              type="number"
              min={0}
              value={purchase || ""}
              onChange={(e) => this.inputChange(e, "purchase")}
            />
          </div>
          <div className="row">
            <div className="title">Currency :</div>
            <Select
              options={currencyList}
              className="control"
              value={currency || {}}
              placeholder="Please select currency..."
              onChange={(e) => this.inputChange(e, "currency")}
            />
          </div>
          <div className="row">
            <div className="title">Icon :</div>
            <div className="file">
              <input
                type="file"
                placeholder="image"
                className="file-input"
                onChange={(e) => {
                  this.inputChange(e, "image");
                }}
              />
              {image !== null ? (
                <div className="img">
                  <img src={BASE_URL + image} />
                </div>
              ) : (
                <React.Fragment>
                  <FileIcon size={50} color="#5864ff" />
                  <p className="p">
                    Drop your image here,or browse <br />
                    JPG,PNG and GIF files are allowed
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="row">
            <div className="title">Added Content :</div>
            <input
              type="text"
              onChange={(e) => this.inputChange(e, "add")}
              value={addContent || ""}
            />
          </div>
          <div className="row">
            <div className="title">Requirement Template(client) :</div>
            <textarea
              className="sku-textarea"
              value={request || ""}
              onChange={(e) => this.inputChange(e, "request")}
            ></textarea>
          </div>
          <div className="row">
            <div className="title">Requirement Template(staff):</div>
            <textarea
              className="sku-textarea"
              value={staffRequest || ""}
              onChange={(e) => this.inputChange(e, "staffRequest")}
            ></textarea>
          </div>
          <div className="row">
            <div className="title">Sku Details :</div>
            <EditorMix ref={this.skuDetailRef} value={skuDetail || ""} />
          </div>
          <div className="row">
            <div className="title">After-sales Policy :</div>
            <EditorMix ref={this.policyRef} value={policy || ""} />
          </div>
          <div className="row">
            <div className="statuBar">
              <p className="btn-p" onClick={this.clickToMove}>
                ON
              </p>
              <p className="btn-p" onClick={this.clickToMove}>
                OFF
              </p>
              <div className={`btn ${isClick ? "move" : ""}`}></div>
            </div>
          </div>
          <div className="footer">
            <Submit
              size={40}
              color="#5864ff"
              className="icon"
              onClick={this.submit}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddSku;
