import React from "react";

const MapAddScale = ({
  viewBox = "0 0 24 24",
  size = "24",
  color = "#999",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="168835"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M848 474H550V152h-76v322H176c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h298v322h76V550h298c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"
      fill={color}
      p-id="168836"
    ></path>
  </svg>
);


export default MapAddScale;