import React, { useState, useImperativeHandle } from "react";
import success from "../../image/success.gif";
import "../../style/common/success.scss";

const Success = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [gifKey, setGifKey] = useState(Date.now());

  // 暴露给父组件的方法
  useImperativeHandle(ref, () => ({
    toggle: () => {
      setLoading((prevLoading) => !prevLoading);
      setGifKey(Date.now());
    }, // 切换加载状态
  }));

  const handleReloadGif = () => {
    setGifKey(Date.now()); // 通过更新 key 使图片重新加载
  };

  return (
    <>
      {loading && (
        <div className="success-loading">
          <img key={gifKey} src={success} alt="success" className="gif" />
        </div>
      )}
    </>
  );
});

export default Success;
