import React from "react";

const SubScale = ({
  viewBox = "0 0 24 24",
  color = "#999",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="170840"
    width={size}
    height={size}
    {...props}
  >
    <path d="M102.4 563.2V460.8h819.2v102.4z" fill={color} p-id="170841"></path>
  </svg>
);


export default SubScale;