import React, { Component } from "react";
import "../style/entrance.scss";
import { setAPIAuthorizationToken } from "../apis/API";
import LogOutIcon from "../icon/LogOutIcon";
import HamburgerIcon from "../icon/HamburgerIcon";
import AllowRight from "../icon/AllowRight";
import cookie from "react-cookies";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  Redirect,
} from "react-router-dom";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import LeftBar from "./common/LeftBar";
import Home from "./Home";
import User from "./User";
import Company from "./Company";
import Order from "./Order";
import AddUser from "./AddUser";
import AddCompany from "./AddCompany";
import Billing from "./Billing";
import NotifyIcon from "../icon/NotifyIcon";
import Notice from "../icon/Notice";
import Product from "./Product";
import Sku from "./Sku";
import Country from "./Country";
import AddCountry from "./AddCountry";
import Category from "./Category";
import AddCategory from "./AddCategory";
import AddSku from "./AddSku";
import EditOrder from "./EditOrder";
import News from "./News";
import AddNews from "./AddNews";
import Comment from "./Comment";
import AddComment from "./AddComment";
import EditBilling from "./EditBilling";
import VIP from "./VIP";
import AddVIP from "./AddVIP";
import Percent from "./Percent";
import Dashboard from "./Dashboard";
import * as GetBillingListAPI from "../apis/GetBillingListAPI";
import * as GetOrderListAPI from "../apis/GetOrderListAPI";

import NoContent from "../icon/NoContent";
import Process from "../icon/Processing";
import PaySuucess from "../icon/PaySuccess";
import PayError from "../icon/PayError";
import Pending from "../icon/Pending";

import ChartIcon from "../icon/ChartIcon";
import UserIcon from "../icon/UserIcon";
import NewsIcon from "../icon/NewsIcon";
import OrderIcon from "../icon/OrderIcon";
import HomeIcon from "../icon/HomeIcon";
import CompanyIcon from "../icon/Company";
import ChargeIcon from "../icon/Charge";
import ShopIcon from "../icon/Shop";
import CountryIcon from "../icon/Country";
import SkuIcon from "../icon/Sku";
import CategoryIcon from "../icon/CategoryIcon";
import CommentIcon from "../icon/CommentIcon";
import VIPIcon from "../icon/VIPIcon";
import PercentIcon from "../icon/Percent";
import * as GetUserAPI from "../apis/GetUserAPI";
import CloseIcon from "../icon/CloseIcon";

const iconColor = "#424242";
const mobileIconColor = "rgb(115, 123, 139)";
const iconSize = 15;
const bounceColor = "#fff";
const activeColor = "rgb(84, 105, 212)";

class Entrance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reduce: true,
      username: "",
      orderBox: false,
      billingBox: false,
      orderList: [],
      billingList: [],
      usertype: "",
      user: null,
      mobile: false,
    };
    this.routeLogin = this.routeLogin.bind(this);
    this.clickReduce = this.clickReduce.bind(this);
    this.showNotifyBox = this.showNotifyBox.bind(this);
    this.routePage = this.routePage.bind(this);
    this.routePageDetail = this.routePageDetail.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    const { history } = this.props;
    if (param) {
      this.setState({
        username: param.user.username,
        usertype: param.user.roleType,
      });
      setAPIAuthorizationToken(param.token);
      this.getBillingList();
      this.getOrderList("processing");
      this.getUser();
    } else {
      history.push("/Login");
    }
  }

  clickReduce() {
    this.setState({ reduce: !this.state.reduce });
  }
  searchInput(e) {
    this.setState({ searchValue: e.target.value });
  }

  routeLogin() {
    const { history } = this.props;
    let param = cookie.load("token");
    cookie.remove("token", { path: "/" });
    history.push("/Login");
    window.dispatchEvent(
      new CustomEvent("showAlert", {
        detail: "Successfully Logout",
      })
    );
  }

  getUser() {
    GetUserAPI.getUser()
      .then((res) => {
        var data = res.data.data;
        if (data) {
          this.setState({
            user: data,
          });
        }
      })
      .catch((err) => {});
  }

  showNotifyBox(type) {
    switch (type) {
      case "order":
        this.setState({ orderBox: !this.state.orderBox }, () => {
          this.getBillingList();
          this.getOrderList("processing");
        });
        break;
      case "billing":
        this.setState({ billingBox: !this.state.billingBox }, () => {
          this.getBillingList();
          this.getOrderList("processing");
        });
        break;
      default:
        break;
    }
  }

  getBillingList() {
    GetBillingListAPI.getBillingListNoPage("processing").then((res) => {
      var data = res.data.data;
      if (data) {
        this.setState({ billingList: data.list });
      }
    });
  }

  getOrderList(type) {
    GetOrderListAPI.getOrderListNoPage(type).then((res) => {
      var data = res.data.data;
      if (data) {
        this.setState({ orderList: data.list });
      }
    });
  }

  routePage(id, type) {
    let { history } = this.props;
    let { usertype } = this.state;
    switch (type) {
      case "order":
        history.push(`/Entrance/EditOrder/${id}`);
        this.showNotifyBox(type);
        break;
      case "billing":
        if (usertype === "admin") {
        }
        history.push(`/Entrance/EditBilling/${id}`);
        this.showNotifyBox(type);
        break;
      default:
        break;
    }
  }

  routePageDetail(type) {
    const { history } = this.props;
    switch (type) {
      case "Login":
        cookie.remove("token", { path: "/" });
        history.push(`/${type}`);
        break;
      default:
        history.push(`/Entrance/${type}`);
        this.setState({ mobile: !this.state.mobile });
        break;
    }
  }

  render() {
    const {
      reduce,
      username,
      orderBox,
      billingBox,
      billingList,
      orderList,
      mobile,
      user,
    } = this.state;
    let { location } = this.props;

    return (
      <React.Fragment>
        <div className="body-container">
          {/* menu */}
          <div className={`mobile-menu ${mobile ? "menu-show" : ""}`}>
            <CloseIcon
              className="close icon"
              size={15}
              onClick={() => this.setState({ mobile: false })}
            />
            <div className="ul">
              <div
                className={`menu-li ${
                  location.pathname.indexOf("Home") > 0 ? "moblie-active" : ""
                }`}
                onClick={() => this.routePageDetail("Home")}>
                <div className="img">
                  <img src={BASE_URL + (user && user.image)} alt="" />
                </div>{" "}
                Hi,{user && user.username}
              </div>
              <div
                className={`menu-li ${
                  location.pathname.indexOf("Dashboard") > 0
                    ? "moblie-active"
                    : ""
                }`}
                onClick={() => this.routePageDetail("Dashboard")}>
                {location.pathname.indexOf("Dashboard") > 0 ? (
                  <HomeIcon
                    size={iconSize}
                    className="menu-icon"
                    color={activeColor}
                  />
                ) : (
                  <HomeIcon
                    size={iconSize}
                    className="menu-icon"
                    color={mobileIconColor}
                  />
                )}
                DASHBOARD
              </div>
              {user && user.roleType === "admin" && (
                <>
                  <div
                    className={`menu-li ${
                      location.pathname.indexOf("VIP") > 0 ||
                      location.pathname.indexOf("AddVIP") > 0
                        ? "moblie-active"
                        : ""
                    }`}
                    onClick={() => this.routePageDetail("VIP")}>
                    {location.pathname.indexOf("VIP") > 0 ||
                    location.pathname.indexOf("AddVIP") > 0 ? (
                      <VIPIcon
                        size={iconSize}
                        className="menu-icon"
                        color={activeColor}
                      />
                    ) : (
                      <VIPIcon
                        size={iconSize}
                        className="menu-icon"
                        color={mobileIconColor}
                      />
                    )}
                    VIP
                  </div>
                </>
              )}
              {user &&
                (user.roleType === "admin" || user.roleType === "client") && (
                  <>
                    <div
                      className={`menu-li ${
                        location.pathname.indexOf("Product") > 0
                          ? "moblie-active"
                          : ""
                      }`}
                      onClick={() => this.routePageDetail("Product")}>
                      <ShopIcon
                        size={iconSize}
                        className="menu-icon bounce-icon"
                        color={activeColor}
                      />
                      SHOP
                    </div>
                  </>
                )}
              <div
                className={`menu-li ${
                  location.pathname.indexOf("User") > 0 ||
                  location.pathname.indexOf("AddUser") > 0
                    ? "moblie-active"
                    : ""
                }`}
                onClick={() => this.routePageDetail("User")}>
                {location.pathname.indexOf("User") > 0 ||
                location.pathname.indexOf("AddUser") > 0 ? (
                  <UserIcon
                    size={iconSize}
                    className="menu-icon"
                    color={activeColor}
                  />
                ) : (
                  <UserIcon
                    size={iconSize}
                    className="menu-icon"
                    color={mobileIconColor}
                  />
                )}
                AGENT
              </div>
              {user && user.roleType === "admin" && (
                <>
                  {" "}
                  <div
                    className={`menu-li ${
                      location.pathname.indexOf("Category") > 0 ||
                      location.pathname.indexOf("AddCategory") > 0
                        ? "moblie-active"
                        : ""
                    }`}
                    onClick={() => this.routePageDetail("Category")}>
                    {location.pathname.indexOf("Category") > 0 ||
                    location.pathname.indexOf("AddCategory") > 0 ? (
                      <CategoryIcon
                        size={iconSize}
                        className="menu-icon"
                        color={activeColor}
                      />
                    ) : (
                      <CategoryIcon
                        size={iconSize}
                        className="menu-icon"
                        color={mobileIconColor}
                      />
                    )}
                    CATEGORY
                  </div>
                  <div
                    className={`menu-li ${
                      location.pathname.indexOf("Country") > 0 ||
                      location.pathname.indexOf("AddCountry") > 0
                        ? "moblie-active"
                        : ""
                    }`}
                    onClick={() => this.routePageDetail("Country")}>
                    {location.pathname.indexOf("Country") > 0 ||
                    location.pathname.indexOf("AddCountry") > 0 ? (
                      <CountryIcon
                        size={iconSize}
                        className="menu-icon"
                        color={activeColor}
                      />
                    ) : (
                      <CountryIcon
                        size={iconSize}
                        className="menu-icon"
                        color={mobileIconColor}
                      />
                    )}
                    COUNTRY
                  </div>{" "}
                  <div
                    className={`menu-li ${
                      location.pathname.indexOf("Sku") > 0 ||
                      location.pathname.indexOf("AddSku") > 0
                        ? "moblie-active"
                        : ""
                    }`}
                    onClick={() => this.routePageDetail("Sku")}>
                    {location.pathname.indexOf("Sku") > 0 ||
                    location.pathname.indexOf("AddSku") > 0 ? (
                      <SkuIcon
                        size={iconSize}
                        className="menu-icon"
                        color={activeColor}
                      />
                    ) : (
                      <SkuIcon
                        size={iconSize}
                        className="menu-icon"
                        color={mobileIconColor}
                      />
                    )}
                    SKU
                  </div>
                </>
              )}

              {user &&
                (user.roleType === "admin" || user.roleType === "staff") && (
                  <>
                    {" "}
                    <div
                      className={`menu-li ${
                        location.pathname.indexOf("Company") > 0 ||
                        location.pathname.indexOf("AddCompany") > 0
                          ? "moblie-active"
                          : ""
                      }`}
                      onClick={() => this.routePageDetail("Company")}>
                      {location.pathname.indexOf("Company") > 0 ||
                      location.pathname.indexOf("AddCompany") > 0 ? (
                        <CompanyIcon
                          size={iconSize}
                          className="menu-icon"
                          color={activeColor}
                        />
                      ) : (
                        <CompanyIcon
                          size={iconSize}
                          className="menu-icon"
                          color={mobileIconColor}
                        />
                      )}
                      COMPANY
                    </div>
                  </>
                )}
              <div
                className={`menu-li ${
                  location.pathname.indexOf("Billing") > 0 ||
                  location.pathname.indexOf("EditBilling") > 0
                    ? "moblie-active"
                    : ""
                }`}
                onClick={() => this.routePageDetail("Billing")}>
                {location.pathname.indexOf("Billing") > 0 ||
                location.pathname.indexOf("EditBilling") > 0 ? (
                  <ChargeIcon
                    size={iconSize}
                    className="menu-icon"
                    color={activeColor}
                  />
                ) : (
                  <ChargeIcon
                    size={iconSize}
                    className="menu-icon"
                    color={mobileIconColor}
                  />
                )}
                BILLING
              </div>
              <div
                className={`menu-li ${
                  location.pathname.indexOf("Order") > 0 ||
                  location.pathname.indexOf("EditOrder") > 0
                    ? "moblie-active"
                    : ""
                }`}
                onClick={() => this.routePageDetail("Order")}>
                {location.pathname.indexOf("Order") > 0 ||
                location.pathname.indexOf("EditOrder") > 0 ? (
                  <OrderIcon
                    size={iconSize}
                    className="menu-icon"
                    color={activeColor}
                  />
                ) : (
                  <OrderIcon
                    size={iconSize}
                    className="menu-icon"
                    color={mobileIconColor}
                  />
                )}
                ORDER
              </div>
              <div
                className={`menu-li ${
                  location.pathname.indexOf("Percent") > 0
                    ? "moblie-active"
                    : ""
                }`}
                onClick={() => this.routePageDetail("Percent")}>
                {location.pathname.indexOf("Percent") > 0 ? (
                  <PercentIcon
                    size={iconSize}
                    className="menu-icon"
                    color={activeColor}
                  />
                ) : (
                  <PercentIcon
                    size={iconSize}
                    className="menu-icon"
                    color={mobileIconColor}
                  />
                )}
                PERCENT
              </div>
              {user && user.roleType === "admin" && (
                <>
                  <div
                    className={`menu-li ${
                      location.pathname.indexOf("New") > 0
                        ? "moblie-active"
                        : ""
                    }`}
                    onClick={() => this.routePageDetail("News")}>
                    {location.pathname.indexOf("New") > 0 ||
                    location.pathname.indexOf("AddNew") > 0 ? (
                      <NewsIcon
                        size={iconSize}
                        className="menu-icon"
                        color={activeColor}
                      />
                    ) : (
                      <NewsIcon
                        size={iconSize}
                        className="menu-icon"
                        color={mobileIconColor}
                      />
                    )}
                    NEW
                  </div>
                  <div
                    className={`menu-li ${
                      location.pathname.indexOf("Comment") > 0 ||
                      location.pathname.indexOf("AddComment") > 0
                        ? "moblie-active"
                        : ""
                    }`}
                    onClick={() => this.routePageDetail("Comment")}>
                    {location.pathname.indexOf("Comment") > 0 ||
                    location.pathname.indexOf("AddComment") > 0 ? (
                      <CommentIcon
                        size={iconSize}
                        className="menu-icon"
                        color={activeColor}
                      />
                    ) : (
                      <CommentIcon
                        size={iconSize}
                        className="menu-icon"
                        color={mobileIconColor}
                      />
                    )}
                    COMMENT
                  </div>
                </>
              )}
              <div
                className={`menu-li ${
                  location.pathname.indexOf("Login") > 0 ? "moblie-active" : ""
                }`}
                onClick={() => this.routePageDetail("Login")}>
                {location.pathname.indexOf("Login") > 0 ? (
                  <LogOutIcon
                    size={iconSize}
                    className="menu-icon"
                    color={activeColor}
                  />
                ) : (
                  <LogOutIcon
                    size={iconSize}
                    className="menu-icon"
                    color={mobileIconColor}
                  />
                )}
                Logout / Login
              </div>
            </div>
          </div>
          {/* 左 */}
          <div className={`left-bar ${reduce ? "reduce-left" : ""}`}>
            <LeftBar {...this.props} />
          </div>
          {/* 右 */}
          <div className={`right-bar ${reduce ? "reduce-right" : ""}`}>
            <header>
              <div className="head-bar">
                <div className="nav-header">
                  <div className="header-left">
                    {reduce ? (
                      <AllowRight
                        size={25}
                        color={iconColor}
                        onClick={this.clickReduce}
                        className="icon head-icon"
                      />
                    ) : (
                      <HamburgerIcon
                        size={30}
                        color={iconColor}
                        onClick={this.clickReduce}
                        className="icon head-icon"
                      />
                    )}
                    <HamburgerIcon
                      size={24}
                      color={iconColor}
                      className="icon mobile-icon"
                      onClick={() => this.setState({ mobile: !mobile })}
                    />
                    <h1>
                      {location.pathname.indexOf("/Home") >= 0 && "HOME"}
                      {location.pathname.indexOf("/Dashboard") >= 0 &&
                        "DASHBOARD"}
                      {location.pathname.indexOf("/User") >= 0 && "AGENT"}
                      {location.pathname.indexOf("/Company") >= 0 && "COMPANY"}
                      {location.pathname.indexOf("/Sku") >= 0 && "SKU"}
                      {location.pathname.indexOf("/Country") >= 0 && "COUNTRY"}
                      {location.pathname.indexOf("/Order") >= 0 && "ORDER"}
                      {location.pathname.indexOf("/Product") >= 0 && "PRODUCT"}
                      {location.pathname.indexOf("/Billing") >= 0 && "BILLING"}
                      {location.pathname.indexOf("/Category") >= 0 &&
                        "CATEGORY"}
                      {location.pathname.indexOf("/News") >= 0 && "NEW"}
                      {location.pathname.indexOf("/VIP") >= 0 && "VIP"}
                      {location.pathname.indexOf("/Percent") >= 0 && "PERCENT"}
                      {location.pathname.indexOf("/Comment") >= 0 && "COMMENT"}
                      {location.pathname.indexOf("/AddComment") >= 0 &&
                        "ADDCOMMENT"}
                      {location.pathname.indexOf("/EditOrder") >= 0 &&
                        "EDITORDER"}
                      {location.pathname.indexOf("/EditBilling") >= 0 &&
                        "EDITBILLING"}
                      {location.pathname.indexOf("/AddUser") >= 0 && "ADDUSER"}
                      {location.pathname.indexOf("/AddSku") >= 0 && "ADDSKU"}
                      {location.pathname.indexOf("/AddNews") >= 0 && "ADDNEW"}
                      {location.pathname.indexOf("/AddCategory") >= 0 &&
                        "ADDCATEGORY"}
                      {location.pathname.indexOf("/AddCompany") >= 0 &&
                        "AddCompany"}
                      {location.pathname.indexOf("/AddCountry") >= 0 &&
                        "ADDCOUNTRY"}
                      {location.pathname.indexOf("/AddVIP") >= 0 && "ADDVIP"}
                    </h1>
                  </div>
                  <div className="header-right">
                    <div className="head-li">
                      <NotifyIcon
                        size={30}
                        color={iconColor}
                        className="head-icon"
                        onClick={() => this.showNotifyBox("order")}
                      />
                      <div className="tip"></div>
                      <div
                        className={`order-box ${
                          orderBox ? "orderBox-show" : ""
                        }`}>
                        <div className="square"></div>
                        {orderList.length > 0 ? (
                          <div className="notice-content">
                            {orderList.map((item, index) => {
                              return (
                                <div
                                  className="notice-li"
                                  key={index}
                                  onClick={() =>
                                    this.routePage(item.id, "order")
                                  }>
                                  <div className="left">
                                    <div className={`state-btn ${item.status}`}>
                                      {item.status}
                                    </div>
                                    <div className="li-value">
                                      {item.username}
                                    </div>
                                  </div>
                                  <div className="li-value">
                                    <img
                                      src={BASE_URL + item.producticon}
                                      alt=""
                                    />
                                  </div>
                                  <div className="li-value">
                                    <span>${item.price}</span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="no-content">
                            <NoContent size={100} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="head-li">
                      <Notice
                        size={30}
                        color={iconColor}
                        className="head-icon"
                        onClick={() => this.showNotifyBox("billing")}
                      />
                      <div className="tip"></div>
                      <div
                        className={`billing-box ${
                          billingBox ? "billing-show" : ""
                        }`}>
                        {" "}
                        <div className="square"></div>
                        {billingList.length > 0 ? (
                          <div className="notice-content">
                            {billingList.map((item, index) => {
                              return (
                                <div
                                  className="notice-li"
                                  key={index}
                                  onClick={() =>
                                    this.routePage(item.id, "billing")
                                  }>
                                  <div className="left">
                                    <div className={`state-btn ${item.status}`}>
                                      {item.status}
                                    </div>
                                    <div className="li-value">
                                      {item.username}
                                    </div>
                                  </div>
                                  <div className="li-value">
                                    <span>${item.amount}</span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="no-content">
                            <NoContent size={100} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="profile">
                      <div className="left">
                        <div className="user-text">
                          <p>Hi,</p>
                          <h6>{username}</h6>
                        </div>
                      </div>
                      {/* logout */}
                      <div className="logout" onClick={this.routeLogin}>
                        <LogOutIcon
                          size={28}
                          color={iconColor}
                          className="logout-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="page-contain">
              <Switch>
                <Route path="/Entrance/AddVIP" component={AddVIP}></Route>
                <Route
                  path="/Entrance/EditBilling"
                  component={EditBilling}></Route>
                <Route
                  path="/Entrance/AddComment"
                  component={AddComment}></Route>
                <Route path="/Entrance/AddNews" component={AddNews}></Route>
                <Route path="/Entrance/EditOrder" component={EditOrder}></Route>
                <Route path="/Entrance/AddSku" component={AddSku}></Route>
                <Route
                  path="/Entrance/AddCategory"
                  component={AddCategory}></Route>
                <Route
                  path="/Entrance/AddCountry"
                  component={AddCountry}></Route>
                <Route
                  path="/Entrance/AddCompany"
                  component={AddCompany}></Route>
                <Route path="/Entrance/AddUser" component={AddUser}></Route>
                <Route path="/Entrance/VIP" component={VIP}></Route>
                <Route path="/Entrance/Comment" component={Comment}></Route>
                <Route path="/Entrance/News" component={News}></Route>
                <Route path="/Entrance/Category" component={Category}></Route>
                <Route path="/Entrance/Billing" component={Billing}></Route>
                <Route path="/Entrance/Country" component={Country}></Route>
                <Route path="/Entrance/Sku" component={Sku}></Route>
                <Route path="/Entrance/Product" component={Product}></Route>
                <Route path="/Entrance/Percent" component={Percent}></Route>
                <Route path="/Entrance/Order" component={Order}></Route>
                <Route path="/Entrance/Company" component={Company}></Route>
                <Route path="/Entrance/User" component={User}></Route>
                <Route path="/Entrance/Home" component={Home}></Route>
                <Route path="/Entrance/Dashboard" component={Dashboard}></Route>
                <Redirect to="/Entrance/Dashboard" />
              </Switch>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Entrance;
