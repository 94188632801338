import React, { Component } from "react";
import CloseIcon from "../icon/CloseIcon";
import "../style/editBilling.scss";
import Loading from "./common/Loading";
import Submit from "../icon/SubmitIcon";
import * as SubmitBillingAPI from "../apis/SubmitBillingAPI";
import * as GetBillingAPI from "../apis/GetBillingAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";

import FileIcon from "../icon/FileIcon";
import { upload } from "../utils/UploadFile";
import cookie from "react-cookies";
import { img } from "@douyinfe/semi-ui/lib/es/markdownRender/components";
import { Select } from "antd";
import ReactFlagsSelect from "react-flags-select";
import { foundCountry } from "../utils/CountryMap";
import { setAPIAuthorizationToken } from "../apis/API";
import moment from "moment";

import AlertBox from "./common/AlertBox";
import { getId } from "../utils/RoutePage";

class EditBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      countrycode: null,
      country: "",
      id: 0,
      isClick: true,
      orderDetail: {},
      roleType: null,
      description: "",
      type: "",
    };
    this.handlePreviewAlert = this.handlePreviewAlert.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.back = this.back.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.confirmHandler = this.confirmHandler.bind(this);
  }

  alertRef = React.createRef();

  componentDidMount() {
    let param = cookie.load("token");
    setAPIAuthorizationToken(param.token);
    let { location } = this.props;
    let id = getId(location, "EditBilling");
    if (id) {
      this.toggleShow();
      GetBillingAPI.getBilling(id)
        .then((res) => {
          this.toggleShow();
          console.log(res);
          var data = res.data.data;
          if (data) {
            this.setState({
              id: parseInt(id),
              countrycode: data.countrycode,
              status: data.status,
              isClick: data.status > 0 ? true : false,
              country: foundCountry(data.countrycode),
              orderDetail: data,
              roleType: param.user.roleType,
            });
          }
        })
        .catch((err) => {
          this.toggleShow();
        });
    }
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  handlePreviewAlert(type) {
    this.handleToggle();
    this.setState({ type });
  }

  handleToggle() {
    if (this.alertRef) {
      this.alertRef.current.toggleAlertShow();
    }
  }

  confirmHandler() {
    this.toggleShow();
    let param = {
      id: this.state.id,
      status: this.state.type,
    };
    console.log(param);
    SubmitBillingAPI.submitBilling(param)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        console.log(msg);
        if (code === 1) {
          if (this.state.id !== 0) {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "Audit successfully",
              })
            );
            this.back();
          } else {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "Audit Failed",
              })
            );
            this.back();
          }
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  changeInput(e) {
    let { orderDetail, description } = this.state;
    orderDetail.description = e.target.value;
    this.setState({ orderDetail, description: e.target.value });
  }

  render() {
    const { orderDetail, roleType, description } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <AlertBox
          ref={this.alertRef}
          {...this.props}
          confirmHandler={this.confirmHandler}
          toggleAlertShow={this.handleToggle}
        />
        <div className="editBilling-page">
          <CloseIcon
            size={30}
            className="icon close"
            color="#5864ff"
            onClick={this.back}
          />
          <div
            className={`type  ${
              orderDetail.amount && orderDetail.amount.indexOf("-") >= 0
                ? "withdraw"
                : "charge"
            }`}
          >
            {orderDetail.type}
          </div>
          <div className="row"></div>
          <div className="row">
            <div className="title-row">
              <div className="title">PRICE </div>
              <div className="value">USD {orderDetail.amount}</div>
            </div>
          </div>
          <div className="row">
            <div className="title-row">
              <div className="title">DESCRIPTION</div>
              <div className="value">{orderDetail.description}</div>
            </div>
          </div>
          <div className="row">
            <div className="title-row">
              <div className="title">
                {orderDetail.type === "charge" && "WALLET-ADDRESS"}{" "}
                {orderDetail.type === "withdraw" && "RECEIVING-ACCOUNT"}
              </div>
              <div className="value">{orderDetail.address}</div>
            </div>
          </div>
          <div className="row">
            <div className="title-row">
              <div className="title">CREATE TIME </div>
              <div className="value">
                {" "}
                {moment(orderDetail.createtime * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
              </div>
            </div>
          </div>
          <div className="footer">
            {roleType === "admin" && (
              <React.Fragment>
                <div
                  className="btn fail"
                  onClick={() => this.handlePreviewAlert("failed")}
                >
                  Submit for fail{" "}
                  <Submit size={15} color="#fff" className="icon" />
                </div>
                <div
                  className="btn success"
                  onClick={() => this.handlePreviewAlert("successed")}
                >
                  Submit for success{" "}
                  <Submit size={15} color="#fff" className="icon" />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EditBilling;
