import React, { Component } from "react";
import CloseIcon from "../icon/CloseIcon";
import "../style/addVIP.scss";
import Loading from "./common/Loading";
import Submit from "../icon/SubmitIcon";
import * as SubmitVIPAPI from "../apis/SubmitVIPAPI";
import * as GetVIPAPI from "../apis/GetVIPAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";

import FileIcon from "../icon/FileIcon";
import { upload } from "../utils/UploadFile";
import cookie from "react-cookies";
import { img } from "@douyinfe/semi-ui/lib/es/markdownRender/components";
import { Select } from "antd";
import { setAPIAuthorizationToken } from "../apis/API";
import { getId } from "../utils/RoutePage";

class AddVIP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      name: "",
      price: 0,
      id: 0,
      isClick: true,
      status: 1,
      levelList: [
        {
          value: 1,
          label: "level 1",
        },
        {
          value: 2,
          label: "level 2",
        },
        {
          value: 3,
          label: "level 3",
        },
        {
          value: 4,
          label: "level 4",
        },
        {
          value: 5,
          label: "level 5",
        },
      ],
      level: null,
      percentage: 0,
      icon: null,
    };
    this.inputChange = this.inputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.back = this.back.bind(this);
    this.clickToMove = this.clickToMove.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    setAPIAuthorizationToken(param.token);
    let { location } = this.props;
    let id = getId(location, "AddVIP");
    if (id) {
      this.toggleShow();
      GetVIPAPI.getVIP(id)
        .then((res) => {
          this.toggleShow();
          console.log(res);
          var data = res.data.data;
          if (data) {
            this.setState({
              id: parseInt(id),
              name: data.huiyuanname,
              price: data.price,
              icon: data.icon,
              level: data.huiyuandengji,
              percentage: data.chouchengbili,
            });
          }
        })
        .catch((err) => {
          this.toggleShow();
        });
    }
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  async inputChange(e, type) {
    let param = cookie.load("token");
    switch (type) {
      case "name":
        this.setState({ name: e.target.value });
        break;
      case "price":
        this.setState({ price: e.target.value });
        break;
      case "percentage":
        this.setState({ percentage: e.target.value });
        break;
      case "currency":
        console.log(e);
        this.setState({ currency: e });
        break;
      case "icon":
        const url = await upload(e, param.token);
        if (url) {
          this.setState({ icon: url });
        }
        break;
      default:
        break;
    }
  }

  handleChange = (selected, type) => {
    // 更新已选择的选项
    switch (type) {
      case "type":
        this.setState({ type: selected });
        break;
      case "currency":
        this.setState({ currency: selected });
        break;
      case "level":
        this.setState({ level: selected });
        break;
      default:
        break;
    }
  };

  submit() {
    const { id, name, price, percentage, icon, level } = this.state;
    this.toggleShow();
    let param = {
      id,
      huiyuanname: name,
      price,
      icon,
      huiyuandengji: level,
      chouchengbili: percentage,
    };
    SubmitVIPAPI.submitVIP(param)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        console.log(msg);
        if (code === 1) {
          if (id !== 0) {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "Modify VIP successfully",
              })
            );
            this.back();
          } else {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "Add VIP Successfully",
              })
            );
            this.back();
          }
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  clickToMove() {
    let { isClick, status } = this.state;
    status = isClick ? 0 : 1;
    this.setState({ isClick: !this.state.isClick, status });
  }

  render() {
    const { name, price, levelList, level, percentage, icon } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="addVIP-page">
          <CloseIcon
            size={30}
            className="icon close"
            color="#5864ff"
            onClick={this.back}
          />
          <div className="row">
            <div className="title">Name :</div>
            <input
              type="text"
              value={name || ""}
              onChange={(e) => this.inputChange(e, "name")}
            />
          </div>
          <div className="row">
            <div className="title">Icon :</div>
            <div className="file">
              <input
                type="file"
                placeholder="image"
                className="file-input"
                onChange={(e) => {
                  this.inputChange(e, "icon");
                }}
              />
              {icon !== null ? (
                <div className="img">
                  <img src={BASE_URL + icon} />
                </div>
              ) : (
                <React.Fragment>
                  <FileIcon size={50} color="#5864ff" />
                  <p className="p">
                    Drop your image here,or browse <br />
                    JPG,PNG and GIF files are allowed
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="row">
            <div className="title">Level :</div>
            <Select
              options={levelList}
              className="control"
              value={level || {}}
              placeholder="Please select currency..."
              onChange={(selected) => this.handleChange(selected, "level")}
            />
          </div>
          <div className="row">
            <div className="title">Price :</div>
            <input
              type="number"
              min={0}
              value={price || ""}
              onChange={(e) => this.inputChange(e, "price")}
            />
          </div>
          <div className="row">
            <div className="title">Percentage :</div>
            <input
              type="number"
              min={0}
              value={percentage || ""}
              onChange={(e) => this.inputChange(e, "percentage")}
            />
          </div>
          <div className="footer">
            <Submit
              size={40}
              color="#5864ff"
              className="icon"
              onClick={this.submit}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddVIP;
