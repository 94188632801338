import React from "react";

const Scroll = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="164038"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M490.666667 814.933333L128 213.333333c-12.8-21.333333-4.266667-46.933333 12.8-59.733333 8.533333-4.266667 12.8-4.266667 21.333333-4.266667h725.333334c25.6 0 42.666667 17.066667 42.666666 42.666667 0 8.533333 0 17.066667-4.266666 21.333333l-362.666667 601.6c-12.8 21.333333-38.4 25.6-59.733333 12.8-4.266667 0-8.533333-4.266667-12.8-12.8z"
      fill={color}
      p-id="164039"
    ></path>
  </svg>
);

export default Scroll;
