import React from "react";

const TipCloseIcon = ({
  viewBox = "0 0 24 24",
  color = "#999",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="160329"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M168.18242 219.516576l48.700165-48.700165 633.096009 633.096009-48.699142 48.700165L168.18242 219.516576z"
      fill={color}
      p-id="160330"
    ></path>
    <path
      d="M216.882586 852.612585l-48.699142-48.700165 633.096009-633.096009 48.699142 48.699142L216.882586 852.612585z"
      fill={color}
      p-id="160331"
    ></path>
  </svg>
);

export default TipCloseIcon;
