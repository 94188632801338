import React, { Component } from "react";
import "../style/dashboard.scss";
import Loading from "./common/Loading";
import Select from "react-select";
import moment from "moment";
import cookie from "react-cookies";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
import NoContent from "../icon/NoContent";
import { DatePicker } from "@douyinfe/semi-ui";
import { ConfigProvider } from "@douyinfe/semi-ui";
import enUS from "@douyinfe/semi-ui/lib/es/locale/source/en_US"; // 引入英文语言包
import Rise from "../icon/Rise";
import Order from "../icon/Order";
import Wallet from "../icon/Wallet";
import Operational from "../icon/Operational";
import { Timeline } from "antd";
import Edit from "../icon/EditIcon";
import Remove from "../icon/RemoveIcon";
import Stripe from "../image/Stripe.png";
import facebook from "../image/Facebook.png";
import tiktok from "../image/tiktok.png";
import Add from "../icon/Add";
import Close from "../icon/CloseIcon";
import FileIcon from "../icon/FileIcon";
import Submit from "../icon/SubmitIcon";
import { upload } from "../utils/UploadFile";
import { FlagIcon } from "react-flag-kit";
import ChartIcon from "../icon/ChartIcon";
import Country from "../icon/Country";
import Company from "../icon/Company";
import Sku from "../icon/Sku";
import TimePicker from "../icon/TimePicker";
import Slider from "@material-ui/core/Slider";

import * as GetDashboardAPI from "../apis/GetDashboardAPI";
import * as GetCountryListAPI from "../apis/GetCountryListAPI";
import * as GetSkuListAPI from "../apis/GetSkuListAPI";
import * as GetCompanyListAPI from "../apis/GetCompanyListAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import CloseIcon from "../icon/CloseIcon";
import * as RechargeAPI from "../apis/RechargeAPI";

import Map from "./common/Map";
import Scroll from "../icon/Scroll";
import walletImg from "../image/wallet.jpg";
import Copy from "../icon/Copy";
import CopyAlready from "../icon/CopyAlready";
import OnlinePeople from "../icon/OnlinePeople";
import Success from "./common/Success";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      dates: null,
      starttime: "",
      endtime: "",
      dates: [moment().subtract(1, "months").toDate(), moment().toDate()], // 转换成Date对象
      currentYear: moment().year(), // 当前年份
      option: {
        grid: {
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["Money", "Quantity"],
          // right: "2%",
          top: "5%",
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [],
            name: "Money",
            type: "bar",
            itemStyle: { color: "#59bcff" },
            smooth: true,
            // stack: "Total",
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
          },
          {
            data: [],
            name: "Quantity",
            type: "bar",
            // stack: "Total",
            itemStyle: { color: "#4ad994" },
            smooth: true,
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
          },
        ],
      },
      usertype: null,
      skuList: [],
      option2: {
        legend: {
          top: "bottom",
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        series: [
          {
            name: "Nightingale Chart",
            type: "pie",
            radius: [50, 150],
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: [],
          },
        ],
      },
      countryList: [],
      companyList: [],
      walletMoney: 0,
      orderNum: 0,
      companyNum: 0,
      sliderValue: 0,
      chargeMask: false,
      chargeType: null,
      walletAddress: "TUSqsN3RwZ5q2rNo9VzNYNtKvQ1wUjDhCf",
      setIsCopy: false,
      address: "",
      setIsCharge: false,
      receive: "",
    };
    this.handleRangeChange = this.handleRangeChange.bind(this);
    this.getDashboard = this.getDashboard.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.getCountryList = this.getCountryList.bind(this);
    this.routePage = this.routePage.bind(this);
    this.sliderHandleChange = this.sliderHandleChange.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.toggleShowCharge = this.toggleShowCharge.bind(this);
    this.rechargeOrWithdraw = this.rechargeOrWithdraw.bind(this);
    this.changeChargeType = this.changeChargeType.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.changeIsCharge = this.changeIsCharge.bind(this);
  }

  successRef = React.createRef();

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }
  componentDidMount() {
    let param = cookie.load("token");
    if (param) {
      console.log(param);
      this.setState({ usertype: param.user.roleType });
    }
    let { starttime, endtime, dates } = this.state;
    [starttime, endtime] = dates;
    starttime = parseInt(starttime.getTime() / 1000);
    endtime = parseInt(endtime.getTime() / 1000);
    this.getCountryList();
    this.getSkuList();
    this.getCompanyList();
    this.setState({ starttime, endtime }, () => {
      this.getDashboard(starttime, endtime);
    });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getDashboard(starttime, endtime) {
    let param = {
      starttime,
      endtime,
    };

    this.toggleShow();
    GetDashboardAPI.getDashboard(param)
      .then((res) => {
        var data = res.data.data;
        this.setState({
          walletMoney: data.money,
          orderNum: data.chenggongsl,
          companyNum: data.gongsi,
        });
        this.getChart(data.dingdan);
        this.getPieChart(data.sku);
        this.toggleShow();
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  // country
  getCountryList() {
    GetCountryListAPI.getCountryListNoPage().then((res) => {
      var data = res.data.data;
      this.setState({ countryList: data.list });
    });
  }

  toggleSuccess() {
    if (this.successRef) {
      this.successRef.current.toggle();
    }
  }

  // sku
  getSkuList() {
    GetSkuListAPI.getSkuListNoPage().then((res) => {
      var data = res.data.data;
      this.setState({ skuList: data.list });
    });
  }

  // company
  getCompanyList() {
    GetCompanyListAPI.getCompanyListNoPage().then((res) => {
      var data = res.data.data;
      this.setState({ companyList: data.list });
    });
  }

  // line
  getChart(list) {
    let { option } = this.state;
    let xData1 = [];
    let yData1 = [];
    let yData2 = [];
    list.map((item) => {
      xData1.push(item.time_format);
      yData1.push(item.money);
      yData2.push(item.quantity);
    });
    this.setState((prevState) => {
      const newOption = { ...prevState.option }; // 复制整个option对象
      // 更新X轴数据
      newOption.xAxis.data = [...xData1]; // 用展开操作符来创建新的数组
      // 确保更新正确的系列数据，假设您的图表有一个系列
      newOption.series = [
        {
          ...prevState.option.series[0], // 复制原有的系列配置
          data: [...yData1], // 更新数据
        },
        {
          ...prevState.option.series[1], // 复制原有的系列配置
          data: [...yData2], // 更新数据
        },
      ];
      return { option: newOption };
    });
  }

  // pie
  getPieChart(list) {
    let { option2 } = this.state;
    const formattedData = list.map((item) => ({
      value: item.zhi,
      name: item.jian,
    }));
    this.setState((prevState) => {
      const newOption = { ...prevState.option2 };
      newOption.series = [
        {
          ...prevState.option2.series[0], // 复制原有的系列配置
          data: formattedData, // 更新数据
        },
      ];
      return { option2: newOption };
    });
  }

  // 筛选时间
  handleRangeChange(dates) {
    if (dates && dates.length === 2) {
      const starttime = Math.floor(dates[0].valueOf() / 1000); // 获取开始时间戳（秒）
      const endtime = Math.floor(
        moment(dates[1]).endOf("day").valueOf() / 1000
      ); // 获取结束时间戳（秒）

      this.setState({ dates, starttime, endtime }, () => {
        this.getDashboard(starttime, endtime);
      });
    } else {
      this.setState({ starttime: null, endtime: null });
    }
  }

  routePage(type) {
    const { history } = this.props;
    switch (type) {
      case "home":
        history.push("/Home");
        break;
      case "user":
        history.push("/User");
        break;
      case "company":
        history.push("/Company");
        break;
      case "billing":
        history.push("/Billing");
        break;
      case "order":
        history.push("/Order");
        break;
      case "product":
        history.push("/Product");
        break;
      case "sku":
        history.push("/Sku");
        break;
      case "country":
        history.push("/Country");
        break;
      case "category":
        history.push("/Category");
        break;
      case "news":
        history.push("/News");
        break;
      case "comment":
        history.push("/Comment");
        break;
      default:
        break;
    }
  }

  // slider
  sliderHandleChange(e, newValue) {
    this.setState({ sliderValue: newValue });
  }

  changeInput(e, type) {
    switch (type) {
      case "slider":
        this.setState({ sliderValue: e.target.value });
        break;
      case "address":
        this.setState({ address: e.target.value });
        break;
      case "receive":
        this.setState({ receive: e.target.value });
        break;
      default:
        break;
    }
  }

  // charge type
  changeChargeType(type) {
    this.setState({ chargeType: type });
  }

  toggleShowCharge(type) {
    this.setState({
      chargeMask: !this.state.chargeMask,
      chargeType: type,
      setIsCharge: false,
      sliderValue: 0,
    });
  }

  handleCopy = async () => {
    let { walletAddress } = this.state;
    try {
      await navigator.clipboard.writeText(walletAddress);
      this.setState({ setIsCopy: true });
      setTimeout(() => {
        this.setState({ setIsCopy: false });
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  changeIsCharge() {
    this.toggleSuccess();
    setTimeout(() => {
      this.setState({ setIsCharge: !this.state.setIsCharge }, () => {
        this.toggleSuccess();
      });
    }, 1200);
  }

  // recharge or withdraw
  rechargeOrWithdraw(amount, type, address) {
    let param = {
      amount,
      type,
      address,
    };
    if (address === "") {
      return window.dispatchEvent(
        new CustomEvent("showAlertMax", {
          detail: {
            alertShow: true,
            alertType: "warn",
            alertTitle:
              type === "charge"
                ? "Please enter wallet address first !"
                : "Please enter receive account first !",
          },
        })
      );
    }
    RechargeAPI.rechargeOrWithDraw(param).then((res) => {
      var data = res.data;
      if (data.msg === "success") {
        this.toggleShowCharge();
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: "Operation Successful",
          })
        );
      }
    });
  }

  render() {
    const {
      skuList,
      option2,
      option,
      countryList,
      companyList,
      dates,
      walletMoney,
      orderNum,
      companyNum,
      sliderValue,
      chargeMask,
      usertype,
      chargeType,
      walletAddress,
      setIsCopy,
      setIsCharge,
      address,
      receive,
    } = this.state;

    return (
      <React.Fragment>
        <Success ref={this.successRef} />
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className={`charge-mask ${chargeMask ? "charge-show" : ""}`}>
          <div className="charge-box">
            <CloseIcon
              size={20}
              color="#fff"
              className="close icon"
              onClick={this.toggleShowCharge}
            />
            <div className="header">Withdraw or Charge (money)</div>
            <div className="charge-content">
              {/* 充值 */}
              {chargeType === "charge" && (
                <div className="charge-model">
                  <div className="wallet-card">
                    <div className="row">
                      <div className="wallet-img">
                        <img src={walletImg} alt="" />
                      </div>
                      <div className="txt">
                        <div className="p">Recipient's wallet address</div>
                        <div className="p">
                          <b>{walletAddress}</b>
                          {setIsCopy ? (
                            <CopyAlready size={20} />
                          ) : (
                            <Copy
                              size={20}
                              className="icon"
                              onClick={this.handleCopy}
                            />
                          )}
                        </div>
                        <div className="p">
                          When your payment status will change, we'll send to
                          you notification Leave your email
                        </div>
                      </div>
                    </div>
                    {setIsCharge ? (
                      <React.Fragment>
                        <div className="row flex-no">
                          <div className="title">AMOUNT:</div>
                          <div className="input">
                            <div className="usd">USD</div>
                            <input
                              type="number"
                              value={sliderValue}
                              min={0}
                              onChange={(e) => this.changeInput(e, "slider")}
                            />
                          </div>
                        </div>
                        <div className="row flex-no">
                          <div className="slider">
                            <Slider
                              defaultValue={100}
                              value={sliderValue}
                              min={10}
                              max={100000}
                              onChange={this.sliderHandleChange}
                            />
                          </div>
                        </div>
                        <div className="row flex-no">
                          <div className="title">HASH:</div>
                          <input
                            className="input2"
                            type="text"
                            value={address}
                            onChange={(e) => this.changeInput(e, "address")}
                          />
                        </div>

                        <div className="row flex-no">
                          <div className="btn-row">
                            <div
                              className="charge-btn"
                              onClick={() =>
                                this.rechargeOrWithdraw(
                                  sliderValue,
                                  "charge",
                                  address
                                )
                              }>
                              Submit
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="row">
                        <div className="btn" onClick={this.changeIsCharge}>
                          I've already transferred the money.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* 提现 */}
              {chargeType === "withdraw" && (
                <div className="withdraw-model">
                  <div className="row">
                    <div className="title">AMOUNT:</div>
                    <div className="input">
                      <div className="usd">USD</div>
                      <input
                        type="number"
                        value={sliderValue}
                        min={0}
                        onChange={(e) => this.changeInput(e, "slider")}
                      />
                    </div>
                    <div className="slider">
                      <Slider
                        defaultValue={100}
                        value={sliderValue}
                        min={10}
                        max={100000}
                        onChange={this.sliderHandleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="title">WALLET ADDRESS:</div>
                    <input
                      className="input2"
                      type="text"
                      value={receive}
                      onChange={(e) => this.changeInput(e, "receive")}
                    />
                  </div>
                  <div className="row">
                    <div className="btn-row">
                      <div
                        className="charge-btn"
                        onClick={() =>
                          this.rechargeOrWithdraw(
                            sliderValue,
                            "withdraw",
                            receive
                          )
                        }>
                        Withdraw
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="dashboard-page">
          <div className="card-row">
            <div className="card">
              <div className="num">
                <div className="title-logo">
                  <Wallet size={24} color="#fff" />
                </div>
                <p>${walletMoney}</p>
              </div>
              <div className="title">WALLET BALANCE</div>
              <div
                className="btn"
                onClick={() => this.toggleShowCharge("withdraw")}>
                WITHDRAW
              </div>
              <div
                className="btn btn2"
                onClick={() => this.toggleShowCharge("charge")}>
                CHARGE
              </div>
            </div>
            <div className="card">
              <div className="num">
                <div className="title-logo">
                  <Order size={24} color="#fff" />
                </div>
                <p>{orderNum}</p>
              </div>
              <div className="title">SUCCESS ORDER</div>
            </div>
            <div className="card">
              <div className="num">
                <div className="title-logo">
                  <Operational size={24} color="#fff" />
                </div>
                <p>{companyList.length}</p>
              </div>
              <div className="title">PRODUCT</div>
            </div>
            <ConfigProvider locale={enUS}>
              <DatePicker
                onChange={this.handleRangeChange}
                className="datePicker"
                type="dateRange"
                value={dates}
                density="compact"
              />{" "}
            </ConfigProvider>
          </div>
          <div className="third-row">
            <div className="col country">
              <div className="title">
                <div className="left">
                  <div className="title-logo">
                    <Country size={24} />
                  </div>
                  COUNTRY
                </div>
              </div>
              <div className="content">
                {countryList.map((item, index) => {
                  return (
                    <div className="li" key={index}>
                      <div className="left">
                        <FlagIcon
                          code={item.countrycode}
                          style={{ width: "38px", height: "25px" }}
                          className="flag"
                        />
                        <div className="name">{item.country}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="view">
                <Scroll size={20} color="#5864ff" />
              </div>
            </div>
            <div className="col map">
              <div className="icon-header">
                <div className="title-logo">
                  <OnlinePeople size={26} />
                </div>
                ONLINE STAFF
              </div>
              <Map className="ComposableMap" />
            </div>
          </div>

          <div className="chart-row">
            <div className="col">
              <div className="title">
                <div className="title-logo">
                  <ChartIcon size={24} />
                </div>
                <p className="title-p">
                  BEST SELL
                  <br />
                  {/* <span>Best Sell</span> */}
                </p>
              </div>
              <div className="chart-modle">
                <ReactEcharts
                  option={option2}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  echarts={echarts}
                  // 强制刷新
                  key={JSON.stringify(this.state.option2)}
                  theme={"theme_name"}
                  className="chart"
                />
              </div>
            </div>
            <div className="col">
              <div className="title">
                <div className="title-logo">
                  <ChartIcon size={24} />
                </div>
                <p className="title-p">
                  ORDER
                  <br />
                  {/* <span>Order Money && Quantity</span> */}
                </p>
              </div>
              <div className="chart-modle">
                <ReactEcharts
                  option={option}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  echarts={echarts}
                  // 强制刷新
                  key={JSON.stringify(this.state.option)}
                  theme={"theme_name"}
                  className="chart"
                />
              </div>
            </div>
          </div>

          <div className="second-row">
            <div className="col">
              <div className="title">
                <div className="left">
                  <div className="title-logo">
                    <Sku size={24} />
                  </div>
                  SKU
                </div>
              </div>
              <div className="content">
                {skuList.map((item, index) => {
                  return (
                    <div className="li" key={index}>
                      <div className="left">
                        <div className="img">
                          <img src={BASE_URL + item.icon} alt="" />
                        </div>
                        <div className="name">{item.name}</div>
                      </div>
                      <div className="right">
                        <div className="value">
                          ${item.price}{" "}
                          {usertype === "admin" && (
                            <span>${item.costprice}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="view">
                <Scroll size={20} color="#5864ff" />
              </div>
            </div>
            <div className="col">
              <div className="title">
                <div className="left">
                  <div className="title-logo">
                    <Company size={24} />
                  </div>
                  ONLINE COMPANY
                </div>
              </div>
              <div className="content">
                {companyList.map((item, index) => {
                  return (
                    <div className="li" key={index}>
                      <div className="left">
                        <div className="name">{item.productname}</div>
                        <span className={item.menuname.toLowerCase()}>
                          {item.menuname}
                        </span>
                      </div>
                      <div className="right">
                        <div className="sku">
                          {item.skulist &&
                            item.skulist.map((skuItem, skuIndex) => {
                              return (
                                <div className="img" key={skuIndex}>
                                  <img src={BASE_URL + skuItem.icon} />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="view">
                <Scroll size={20} color="#5864ff" />
              </div>
            </div>
          </div>

          <div className="footer">@ Sun Never Sets System</div>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
