import React, { Component } from "react";
import * as GetCodeAPI from "../apis/GetCodeAPI";
import * as LoginAPI from "../apis/LoginAPI";
import Loading from "./common/Loading";
import cookie from "react-cookies";
import { setAPIAuthorizationToken } from "../apis/API";
import queryString from "query-string";
import "../style/userLogin.scss";
import * as SubmitUserAPI from "../apis/SubmitUserAPI";

class UserLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: "https://t.me/stripekyckyc",
      register: true,
      username: "",
      password: "",
      telegram: "",
      code: "",
      inviteCode: "",
      image: "",
      loadingShow: false,
    };
    this.changeBoolean = this.changeBoolean.bind(this);
    this.contactService = this.contactService.bind(this);
    this.regitser = this.regitser.bind(this);
    this.inputchange = this.inputchange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.login = this.login.bind(this);
    this.getCode = this.getCode.bind(this);
  }

  componentDidMount() {
    let params = cookie.load("token");
    let { history } = this.props;
    if (params) {
      history.push("/Entrance");
    }
  }

  changeBoolean(type, value) {
    let { register } = this.state;
    switch (type) {
      case "register":
        register = value;
        break;
      default:
        break;
    }
    this.setState({ register });
  }

  contactService() {
    const { service } = this.state;
    window.open(service, "_blank");
  }

  inputchange(e, type) {
    switch (type) {
      case "username":
        this.setState({ username: e.target.value });
        break;
      case "password":
        this.setState({ password: e.target.value });
        break;
      case "telegram":
        this.setState({ telegram: e.target.value });
        break;
      case "inviteCode":
        this.setState({ inviteCode: e.target.value });
        break;
      case "code":
        this.setState({ code: e.target.value });
        break;
      default:
        break;
    }
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  regitser() {
    let { username, password, telegram, inviteCode } = this.state;
    if (
      username.trim() === "" ||
      password.trim() === "" ||
      telegram.trim() === "" ||
      inviteCode.trim() === ""
    ) {
      return;
    }
    console.log(123456);
    let param = {
      image: "",
      username: this.state.username,
      password: this.state.password,
      telegramId: this.state.telegram,
      roleType: "client",
      nickname: this.state.username,
      invitecode: this.state.inviteCode,
    };
    this.toggleShow();
    SubmitUserAPI.submitUser(param)
      .then((res) => {
        this.toggleShow();
        var data = res.data;
        if (data.msg === "success") {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Register Success !",
            })
          );
          this.changeBoolean("register", true);
          return;
        } else if (data.msg === "error") {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: data.data,
            })
          );
          return;
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  login() {
    let { username, password, code } = this.state;

    this.toggleShow();
    LoginAPI.login(username, password, code)
      .then((res) => {
        console.log(res);
        var data = res.data.data;
        var time = res.data.time;
        if (data) {
          time = new Date(new Date(time * 1000 + 3 * 24 * 60 * 60 * 1000));
          setAPIAuthorizationToken(data.token);
          cookie.save("token", data, {
            expires: "",
            path: "/",
            secure: true, // 仅在 HTTPS 上传输
            sameSite: "Strict",
          });
          const { history } = this.props;
          history.push("/Entrance");
        } else if (data === null) {
          this.toggleShow();
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: res.data.msg,
            })
          );
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  getCode() {
    let { username } = this.state;
    this.toggleShow();
    GetCodeAPI.getCode(username)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;

        const params = queryString.parse(this.props.location.search);
        if (params.developer && params.developer === "qi") {
          const code = msg.replace("code sent:", "");
          this.setState({ code });
        }

        if (code === 1) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Verification Code Sent",
            })
          );
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  render() {
    let { register, username, password, telegram, inviteCode } = this.state;

    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="userLogin">
          <div className="contain">
            <div
              className={`txt-box ${
                register !== null
                  ? register
                    ? "txt-move-to-right"
                    : "txt-move-to-left"
                  : ""
              } `}>
              <div className="top-cricle"></div>
              <div className="bottom-cricle"></div>
              <h1>{register ? "Hello Friend !" : "Welcome Back !"}</h1>
              <p>
                {register
                  ? "Enter your personal details and start journey with us"
                  : "To keep connected with us please login with your personal info"}
              </p>
              {register ? (
                <div
                  className="btn"
                  onClick={() => this.changeBoolean("register", false)}>
                  SIGN UP
                </div>
              ) : (
                <div
                  className="btn"
                  onClick={() => this.changeBoolean("register", true)}>
                  SIGN IN
                </div>
              )}
            </div>
            <div
              className={`form-box ${
                register !== null
                  ? register
                    ? "form-move-to-left"
                    : "form-move-to-right"
                  : ""
              }`}>
              <h1>{register ? "Sign in to Website" : " Create Account"} </h1>
              <input
                type="text"
                placeholder="Username"
                onChange={(e) => this.inputchange(e, "username")}
              />
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => this.inputchange(e, "password")}
              />
              {register ? (
                ""
              ) : (
                <input
                  type="text"
                  placeholder="Telegram ID"
                  onChange={(e) => this.inputchange(e, "telegram")}
                />
              )}
              {register ? (
                <div className="input-row">
                  <input
                    type="text"
                    placeholder="code"
                    onChange={(e) => this.inputchange(e, "code")}
                  />
                  <div className="btn" onClick={this.getCode}>
                    GET CODE
                  </div>
                </div>
              ) : (
                <input
                  type="text"
                  placeholder="invite code"
                  onChange={(e) => this.inputchange(e, "inviteCode")}
                />
              )}
              <div className="forget" onClick={this.contactService}>
                {register ? "Forget your password ?" : "Get your invite code"}
              </div>
              {register ? (
                <div className="btn" onClick={this.login}>
                  SIGN IN
                </div>
              ) : (
                <div className="btn" onClick={this.regitser}>
                  SIGN UP
                </div>
              )}
            </div>
          </div>
          <div className="mobile-contain">
            <div
              className={`signin-box ${
                register !== null
                  ? register
                    ? "mobile-blur"
                    : "mobile-blur"
                  : ""
              }`}>
              <h1>{register ? "Sign in to Website" : " Create Account"} </h1>
              <input
                type="text"
                placeholder="Username"
                onChange={(e) => this.inputchange(e, "username")}
              />
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => this.inputchange(e, "password")}
              />
              {register ? (
                ""
              ) : (
                <input
                  type="text"
                  placeholder="Telegram ID"
                  onChange={(e) => this.inputchange(e, "telegram")}
                />
              )}
              {register ? (
                <div className="input-row">
                  <input
                    type="text"
                    placeholder="code"
                    onChange={(e) => this.inputchange(e, "code")}
                  />
                  <div className="btn" onClick={this.getCode}>
                    GET CODE
                  </div>
                </div>
              ) : (
                <input
                  type="text"
                  placeholder="invite code"
                  onChange={(e) => this.inputchange(e, "inviteCode")}
                />
              )}
              <div className="forget-row">
                <div className="forget" onClick={this.contactService}>
                  {register ? "Forget your password ?" : "Get your invite code"}
                </div>
                {register ? (
                  <div
                    className="forget-btn"
                    onClick={() => this.changeBoolean("register", false)}>
                    SIGN UP
                  </div>
                ) : (
                  <div
                    className="forget-btn"
                    onClick={() => this.changeBoolean("register", true)}>
                    SIGN IN
                  </div>
                )}
              </div>
              {register ? (
                <div className="btn" onClick={this.login}>
                  SIGN IN
                </div>
              ) : (
                <div className="btn" onClick={this.regitser}>
                  SIGN UP
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserLogin;
