import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/about.scss";

class About extends Component {
  constructor(props) {
    super(props);
  }

  HeaderBarRef = React.createRef();
  FooterBarRef = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar ref={this.HeaderBarRef} {...this.props} />
        <div className="about-page">
          <div className="title">About us</div>
          <p>Welcome/Introduction</p>
          <p>
            About Us Welcome/Introduction Welcome to [Company Name]! We are
            dedicated to [company mission or core objective], providing
            outstanding value to our customers through [description of
            services/products/industry].
          </p>
          <p>Our Mission & Vision</p>
          <p>
            Mission: Our mission is to help customers [solve specific problems]
            by providing innovative and sustainable solutions, improving their
            quality of life. Vision: We strive to be the global leader in
            [industry sector], driving the forward momentum of [industry
            trends].
          </p>
          <p>Our Core Values</p>
          <p>
            Integrity: We are committed to honesty and building trust in all
            relationships.
          </p>
          <p>
            Innovation: We encourage innovation and aim to push boundaries to
            deliver industry-leading solutions.
          </p>
          <p>
            Teamwork: We believe in the power of collaboration and achieving
            common goals through teamwork.
          </p>
          <p>
            Customer First: Our customers’ needs are always at the heart of what
            we do.
          </p>
          <p>Contact Us</p>
          <p>
            If you have any questions or feedback, feel free to reach out to us:
          </p>
          <p>Phone: (+1) 123 456 789</p>
          <p>Email: contact@company.com</p>
          <p>Address: 123 Main St, City, Country</p>
        </div>
        <FooterBar ref={this.FooterBarRef} {...this.props} />
      </React.Fragment>
    );
  }
}

export default About;
