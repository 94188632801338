import React, { Component, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../style/common/edit.scss";
import Loading from "./Loading";
import ApiEndpointConstants, {
  BASE_URL,
} from "../../constants/ApiEndpointConstants";
import axios from "axios";
import cookie from "react-cookies";

class RichTextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorContent: props.value || "",
      imgArr: [],
      loadingShow: false,
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    // this.selectLocalImage = this.selectLocalImage.bind(this);
    this.insertToEditor = this.insertToEditor.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  quillRef = React.createRef();

  modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
      ],
      handlers: {
        image: this.selectLocalImage.bind(this),
      },
    },
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ editorContent: nextProps.value });
    }
  }

  handleEditorChange(content, delta, source, editor) {
    this.setState({ editorContent: content });
    // console.error(content);
  }

  uploadImage(file, imgIndex) {
    let param = cookie.load("token");
    let { imgArr } = this.state;
    const _that = this;
    var formData = new FormData();
    [file].forEach((fileInfo, index) => {
      formData.append("file", fileInfo);
      console.log(formData);
      console.log(fileInfo);
      axios({
        url: BASE_URL + ApiEndpointConstants.UPLOAD,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: param.token,
        },
      })
        .then((res) => {
          console.log("连上了---------");
          let url = res.data.data.fullurl;
          _that.insertToEditor(url);
          console.log(url);
          imgArr[imgIndex + index] = url;
          _that.setState({ imgArr });
          file = "";
        })
        .catch((err) => {});
    });
  }

  selectLocalImage() {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    document.body.appendChild(input); // 将 input 添加到 DOM 中
    input.style.display = "none"; // 隐藏 input

    input.click();
    input.onchange = () => {
      const files = input.files;
      if (files.length > 0) {
        this.uploadImage(files[0]);
      }
      document.body.removeChild(input); // 使用完毕后移除 input
    };
  }

  insertToEditor(url) {
    if (!url) {
      console.error("URL is undefined");
      return;
    }

    const editor = this.quillRef.current.getEditor();
    const range = editor.getSelection(true);
    if (range) {
      editor.insertEmbed(range.index, "image", url);
      // quillEditor.insertEmbed(range.index, 'image', url);
    } else {
      console.warn("No position selected in the editor");
    }
  }

  // handleChange = (editorHtml) => {
  //   this.setState({ editorHtml });
  // };

  render() {
    const { editorContent, loadingShow } = this.state;
    return (
      <React.Fragment>
        <Loading loadingShow={loadingShow} />
        <div className="rich-text-editor">
          <ReactQuill
            ref={this.quillRef}
            value={editorContent || ""}
            onChange={this.handleEditorChange}
            modules={this.modules} // 使用类属性中定义的 modules
            placeholder="Please enter your content ..."
          />
        </div>
      </React.Fragment>
    );
  }
}

export default RichTextEditor;
