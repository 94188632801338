import React, { Component } from "react";
import "../style/product.scss";
import { FlagIcon } from "react-flag-kit";
import * as GetCategoryListAPI from "../apis/GetCategoryListAPI";
import * as GetLianDongListAPI from "../apis/GetLianDongListAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import * as GetUserAPI from "../apis/GetUserAPI";
import CloseIcon from "../icon/CloseIcon";
import * as GetRelationIdAPI from "../apis/GetRelationIdAPI";
import * as CheckoutAPI from "../apis/CheckoutAPI";
import Loading from "./common/Loading";
import AllowUp from "../icon/AllowUp";
import AllowDown from "../icon/AllowDown";
import ReactFlagsSelect from "react-flags-select";
import ReactPhoneInput from "react-telephone-input/lib/withStyles";
import flagImg from "../image/flag.png";
import * as FormValidation from "../utils/FormValidation";
import Success from "./common/Success";
import { routePage } from "../utils/RoutePage";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryList: [],
      country: null,
      categoryList: [],
      companyList: [],
      skuList: [],
      company: null,
      sku: null,
      category: null,
      WalletMoney: null,
      description: "",
      checkoutShow: false,
      relation: null,
      loadingShow: false,
      request: [],
      collapseDetail: false,
      collapsePolicy: false,
    };
    this.select = this.select.bind(this);
    this.getLianDongList = this.getLianDongList.bind(this);
    this.getCategoryList = this.getCategoryList.bind(this);
    this.getWalletMoney = this.getWalletMoney.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.toggleShowCheckout = this.toggleShowCheckout.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
    this.changeCollapse = this.changeCollapse.bind(this);
    this.blurInput = this.blurInput.bind(this);
  }

  successRef = React.createRef();

  componentDidMount() {
    this.getCategoryList();
    this.getWalletMoney();
  }

  getWalletMoney() {
    GetUserAPI.getUser().then((res) => {
      var data = res.data.data;
      this.setState({ WalletMoney: data.money });
    });
  }

  getCategoryList() {
    GetCategoryListAPI.getCategoryListNoPage().then((res) => {
      var data = res.data.data.list;
      data.map((item) => {
        item.selected = false;
      });
      this.setState({ categoryList: data });
    });
  }

  select(index, type) {
    let {
      countryList,
      country,
      categoryList,
      category,
      skuList,
      sku,
      companyList,
      company,
    } = this.state;

    switch (type) {
      case "category":
        const updatedCategoryList = categoryList.map((item, itemIndex) => ({
          ...item,
          selected: itemIndex === index,
        }));
        category = updatedCategoryList[index];
        this.setState(
          {
            categoryList: updatedCategoryList,
            category: category.selected ? category : null,
            sku: null,
            country: null,
            company: null,
          },
          () => {
            let param = { menuid: category.id };
            this.getLianDongList(param);
          }
        );
        break;

      case "country":
        const updatedCountryList = countryList.map((item, itemIndex) => ({
          ...item,
          selected: itemIndex === index,
        }));
        country = updatedCountryList[index];
        this.setState(
          {
            countryList: updatedCountryList,
            country: country.selected ? country : null,
            company: null,
            sku: null,
          },
          () => {
            let param = {
              menuid: category?.id || "",
              guojiaid: country?.id || "",
            };
            this.getLianDongList(param);
          }
        );
        break;

      case "sku":
        const updatedSkuList = skuList.map((item, itemIndex) => ({
          ...item,
          selected: itemIndex === index,
        }));
        sku = updatedSkuList[index];
        this.setState(
          {
            skuList: updatedSkuList,
            sku: sku.selected ? sku : null,
            company: null,
          },
          () => {
            let param = {
              menuid: category?.id || "",
              guojiaid: country?.id || "",
              skuid: sku?.id || "",
            };
            this.getLianDongList(param);
          }
        );
        break;

      case "company":
        const updatedCompanyList = companyList.map((item, itemIndex) => ({
          ...item,
          selected: itemIndex === index,
        }));
        company = updatedCompanyList[index];
        console.log(company);
        this.setState(
          {
            companyList: updatedCompanyList,
            company: company.selected ? company : null,
          },
          () => {
            let param = {
              menuid: category?.id || "",
              guojiaid: country?.id || "",
              skuid: sku?.id || "",
              productid: company?.id || "",
            };
            this.getRelationId(param);
          }
        );
        break;

      default:
        break;
    }
  }

  getLianDongList(param) {
    let { countryList, skuList, companyList } = this.state;

    GetLianDongListAPI.getLianDong(param).then((res) => {
      const data = res.data.data;

      const updatedCountryList = data.guojia
        ? data.guojia.map((item) => ({ ...item, selected: false }))
        : countryList;

      const updatedSkuList = data.sku
        ? data.sku.map((item) => ({ ...item, selected: false }))
        : skuList;

      const updatedCompanyList = data.product
        ? data.product.map((item) => ({ ...item, selected: false }))
        : companyList;

      this.setState({
        countryList: updatedCountryList,
        skuList: updatedSkuList,
        companyList: updatedCompanyList,
      });
    });
  }

  blurInput(e, type, index) {
    let reg;
    let { request } = this.state;
    switch (type) {
      case "email":
        if (!FormValidation.isValidEmail(e.target.value)) {
          request[index].isValid = true;
        } else {
          request[index].isValid = false;
        }
        break;

      case "zip":
        reg = /^[a-zA-Z0-9-_]{4,30}$/;
        if (!reg.test(e.target.value)) {
          request[index].isValid = true;
        } else {
          request[index].isValid = false;
        }
        break;
      case "phone":
        if (FormValidation.isValidPhoneNumber(e)) {
          request[index].isValid = false;
        } else {
          request[index].isValid = true;
        }
        break;

      default:
        break;
    }
    this.setState({ request });
  }

  getRelationId(param) {
    GetRelationIdAPI.getRelationId(param).then((res) => {
      var data = res.data.data;
      if (data && data.list[0] && data.list[0].skuxuqiumoban) {
        const result = data.list[0].skuxuqiumoban.split("\n");
        const parsedResult = result.map((item) => {
          const [key] = item.split(":");
          return (item = {
            title: key,
            value: "",
            isValid: null,
          });
        });
        this.setState({ request: parsedResult });
      }
      this.setState({ relation: data.list[0] });
    });
  }

  changeRequest(e, type, index) {
    let { request } = this.state;
    if (type === "phone") {
      let telephone = e.replace("-", "").replace(" ", "");
      request[index].value = telephone;
    } else {
      request[index].value = e.target.value;
    }
    request[index].isValid = null;
    this.setState({ request });
  }

  changeInput(e) {
    this.setState({ description: e.target.value });
  }

  toggleShowCheckout() {
    this.setState({ checkoutShow: !this.state.checkoutShow });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  toggleSuccess() {
    if (this.successRef) {
      this.successRef.current.toggle();
    }
  }

  changeCollapse(type) {
    switch (type) {
      case "sku":
        this.setState({ collapseDetail: !this.state.collapseDetail });
        break;
      case "policy":
        this.setState({ collapsePolicy: !this.state.collapsePolicy });
        break;
      default:
        break;
    }
  }

  submitOrder() {
    let {
      company,
      description,
      WalletMoney,
      relation,
      request,
      sku,
      category,
    } = this.state;
    console.log(relation);
    let { history } = this.props;
    let total = parseInt(category.menuprice) + parseInt(sku.price);
    console.log(WalletMoney);
    if (parseInt(WalletMoney) <= 0) {
      window.dispatchEvent(
        new CustomEvent("showAlertMax", {
          detail: {
            alertShow: true,
            alertType: "warn",
            alertTitle: "No balance in wallet, please charge first !",
          },
        })
      );
      routePage(history, "Dashboard");
    } else if (parseInt(WalletMoney) < total) {
      window.dispatchEvent(
        new CustomEvent("showAlertMax", {
          detail: {
            alertShow: true,
            alertType: "warn",
            alertTitle: "The balance is insufficient, please charge first !",
          },
        })
      );
      routePage(history, "Dashboard");
    }

    let flagTrim = false;
    let trimItem = null;

    request &&
      request.forEach((item) => {
        if (item.value.trim() === "") {
          flagTrim = true;
          trimItem = item;
          return; // 一旦找到空值，立即停止遍历
        }
      });

    if (flagTrim) {
      window.dispatchEvent(
        new CustomEvent("showAlertMax", {
          detail: {
            alertShow: true,
            alertType: "warn",
            alertTitle: `Please enter ${trimItem.title}`,
          },
        })
      );
      return;
    }

    let xuqiumoban = relation.skuxuqiumoban;
    // 匹配替换value
    request &&
      request.forEach((item) => {
        const regex = new RegExp(`{${item.title}}`, "g");
        xuqiumoban = xuqiumoban.replace(regex, item.value);
      });

    let param = {
      productskurelationid: relation.id,
      description,
      xuqiumoban: xuqiumoban,
      status: "pending",
      fujiawenzi: relation.skufujiawenzi,
      yuangongxuqiumoban: relation.skuyuangongxuqiumoban,
    };
    CheckoutAPI.Chekcout(param).then((res) => {
      var data = res.data;
      if ((data.msg = "success")) {
        this.toggleSuccess();
        setTimeout(() => {
          this.toggleSuccess();
          window.dispatchEvent(
            new CustomEvent("showAlertMax", {
              detail: {
                alertShow: true,
                alertType: "tip",
                alertTitle: "Checkout Successfully !",
              },
            })
          );
          routePage(history, "Order");
        }, 1200);
      } else {
        window.dispatchEvent(
          new CustomEvent("showAlertMax", {
            detail: {
              alertShow: true,
              alertType: "tip",
              alertTitle: data.data,
            },
          })
        );
        this.toggleShowCheckout();
      }
    });
  }

  render() {
    let {
      countryList,
      categoryList,
      skuList,
      companyList,
      category,
      sku,
      company,
      country,
      checkoutShow,
      request,
      relation,
      collapsePolicy,
      collapseDetail,
    } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <Success ref={this.successRef} />
        {/* <AlertBox
          ref={this.alertRef}
          {...this.props}
          confirmHandler={this.confirmHandler}
          toggleAlertShow={this.handleToggle}
        /> */}
        <div className={`checkout-mask ${checkoutShow ? "checkout-show" : ""}`}>
          <div className="checkout-box">
            <CloseIcon
              size={20}
              color="#fff"
              className="close icon"
              onClick={this.toggleShowCheckout}
            />
            <div className="header">CHECKOUT </div>
            <div className="checkout-content">
              <div className="row">
                <div className="title">COMPANY TYPE</div>
                <div className="value">
                  {category && category.menuname} USD{" "}
                  {category && category.menuprice}
                </div>
              </div>
              <div className="row">
                <div className="title">COUNTRY</div>
                <div className="value">
                  <FlagIcon
                    code={country && country.countrycode}
                    style={{ width: "40px", height: "20px" }}
                    className="flag"
                  />
                </div>
              </div>
              <div className="row">
                <div className="title">SKU</div>
                <div className="value">
                  <img
                    src={BASE_URL + (sku && sku.icon)}
                    alt=""
                    className="sku-img"
                  />
                  USD {sku && sku.price}{" "}
                  {relation &&
                    relation.skufujiawenzi &&
                    `+ ${relation.skufujiawenzi}`}
                </div>
              </div>
              <div className="row">
                <div className="title">COMPANY</div>
                <div className="value">{company && company.productname}</div>
              </div>
              <div className="enter-model">
                {request.length > 0 &&
                  request.map((item, index) => {
                    return (
                      <div className="enter-row" key={index}>
                        <div className="title">{item.title}</div>
                        <div className="value">
                          {item.title === "phone" ? (
                            <ReactPhoneInput
                              defaultCountry="us"
                              flagsImagePath={flagImg}
                              onChange={(e) =>
                                this.changeRequest(
                                  e,
                                  item.title.toLowerCase(),
                                  index
                                )
                              }
                              className={`${item.isValid ? "err" : ""}`}
                              onBlur={(e) =>
                                this.blurInput(
                                  e,
                                  item.title.toLowerCase(),
                                  index
                                )
                              }
                            />
                          ) : (
                            <input
                              type="text"
                              onChange={(e) =>
                                this.changeRequest(
                                  e,
                                  item.title.toLowerCase(),
                                  index
                                )
                              }
                              placeholder={`Please enter your ${item.title}`}
                              className={`${item.isValid ? "err" : ""}`}
                              onBlur={(e) =>
                                this.blurInput(
                                  e,
                                  item.title.toLowerCase(),
                                  index
                                )
                              }
                            />
                          )}
                          {item.isValid && <span>Invalid {item.title}</span>}
                        </div>
                      </div>
                    );
                  })}
                <div className="enter-row">
                  <div className="title">DESCRIPTION</div>
                  <div className="value">
                    <textarea
                      placeholder="..."
                      onChange={(e) => this.changeInput(e)}></textarea>
                  </div>
                </div>
              </div>

              <div className="row total">
                <div className="title">TOTAL</div>
                <div className="value">
                  USD{" "}
                  {category &&
                    sku &&
                    (
                      parseInt(category.menuprice) + parseInt(sku.price)
                    ).toFixed(2)}{" "}
                </div>
              </div>
              <div className="btn-row">
                <div className="checkout-btn" onClick={this.submitOrder}>
                  SUBMIT
                </div>
              </div>
              <div className="footer-txt">
                {relation && relation.skuchanpinxiangqing && (
                  <div className="txt-row">
                    <div
                      className="title"
                      onClick={() => this.changeCollapse("sku")}>
                      SKU DETAIL{" "}
                      {collapseDetail ? (
                        <AllowUp size={20} color="rgb(156, 156, 156)" />
                      ) : (
                        <AllowDown size={20} color="rgb(156, 156, 156)" />
                      )}
                    </div>
                    {collapseDetail && (
                      <div
                        className="txt-content"
                        dangerouslySetInnerHTML={{
                          __html: relation && relation.skuchanpinxiangqing,
                        }}></div>
                    )}
                  </div>
                )}
                {relation && relation.skushouhouzhence && (
                  <div className="txt-row">
                    <div
                      className="title"
                      onClick={() => this.changeCollapse("policy")}>
                      AFTER-SALES POLICY{" "}
                      {collapsePolicy ? (
                        <AllowUp size={20} color="rgb(156, 156, 156)" />
                      ) : (
                        <AllowDown size={20} color="rgb(156, 156, 156)" />
                      )}
                    </div>
                    {collapsePolicy && (
                      <div
                        className="txt-content"
                        dangerouslySetInnerHTML={{
                          __html: relation && relation.skushouhouzhence,
                        }}></div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="product-page">
          <div className="row">
            <div className="title">Select Product Type</div>
            <div className="content">
              {categoryList &&
                categoryList.map((item, index) => {
                  return (
                    <div
                      className={`li ${item.selected ? "product-active" : ""}`}
                      key={index}
                      onClick={() => this.select(index, "category")}>
                      <div className="li-title">
                        <div className="key">
                          {" "}
                          {item.menuname}{" "}
                          {item.menuname === "Personal" ? "" : "Company"}{" "}
                        </div>
                        <div className="value">
                          {item.currency} {item.menuprice}
                        </div>
                        <br />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {countryList.length > 0 && category !== null && (
            <div className="row">
              <div className="title">Select Country</div>
              <div className="content">
                {countryList &&
                  countryList.map((item, index) => {
                    return (
                      <div
                        className={`li ${
                          item.selected ? "product-active" : ""
                        }`}
                        key={index}
                        onClick={() => this.select(index, "country")}>
                        <div className="li-title">
                          <div className="key">
                            <FlagIcon
                              code={item.countrycode}
                              style={{ width: "45px", height: "33px" }}
                              className="flag"
                            />
                          </div>
                          <div className="value">{item.country}</div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {skuList.length > 0 && country !== null && (
            <div className="row">
              <div className="title">Select Sku</div>
              <div className="content">
                {skuList &&
                  skuList.map((item, index) => {
                    return (
                      <div
                        className={`li ${
                          item.selected ? "product-active" : ""
                        }`}
                        key={index}
                        onClick={() => this.select(index, "sku")}>
                        <div className="li-title">
                          <div className="key">
                            <div className="img">
                              <img src={BASE_URL + item.icon} alt="" />
                            </div>
                            <p> {item.name}</p>
                          </div>
                          <div className="value">
                            {item.currency} {item.price}{" "}
                            {item.fujiawenzi !== "" && (
                              <div>+ {item.fujiawenzi}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {companyList.length > 0 && sku !== null && (
            <div className="row">
              <div className="title">Select Product</div>
              <div className="content">
                {companyList &&
                  companyList.map((item, index) => {
                    return (
                      <div
                        className={`li ${
                          item.selected ? "product-active" : ""
                        }`}
                        key={index}
                        onClick={() => this.select(index, "company")}>
                        <div className="li-title">
                          <div className="value">{item.productname}</div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {company !== null && (
            <div className="checkout" onClick={this.toggleShowCheckout}>
              CHECKOUT
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Product;
