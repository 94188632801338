import api from "./API";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";

export const getPercentList = (page, per_page, status) => {
  return api.post(`${ApiEndpointConstants.GET_PERCENTLIST}`, {
    page,
    per_page,
    status,
  });
};
