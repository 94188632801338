import React, { useEffect, useState, useLayoutEffect } from "react";
import "../../style/common/headerBar.scss";
import HamburgerIcon from "../../icon/HamburgerIcon";
import { HashLink } from "react-router-hash-link";
import CloseIcon from "../../icon/CloseIcon";

const HeaderBar = React.forwardRef((props, ref) => {
  const [IsScroll, setIsScroll] = useState(false);
  const [IsMenu, setIsMenu] = useState(false);

  const location = props.location;

  useEffect(() => {
    window.addEventListener("scroll", scrollHeaderBar);
    const hash = location.hash;
    let timer = null;
    if (hash) {
      timer = setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }

    // 清理工作：在组件卸载时移除事件监听器并清除定时器
    return () => {
      window.removeEventListener("scroll", scrollHeaderBar);
      clearTimeout(timer); // 清除定时器
    };
  }, [location]);

  useLayoutEffect(() => {
    // 在浏览器绘制之前同步执行，
    // 可以确保所有 DOM 更新完成后再进行滚动，解决了元素未渲染的问题。
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const route = (type) => {
    props.history.push(`/${type}`);
  };

  var scrollHeaderBar = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // 获取页面滚动的垂直高度
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    setIsScroll(scrollTop > 50);
  };

  const MenuShow = () => {
    setIsMenu((prevIsMenu) => !prevIsMenu);
  };

  return (
    <React.Fragment>
      <div
        className={`headerBar ${IsScroll ? "scroll" : ""}`}
        onScroll={(e) => scrollHeaderBar(e)}
      >
        <div className="logo" onClick={() => route("")}>
          Sun Nerver Sets
        </div>
        <div className="ul">
          <li onClick={() => route("")}>Home</li>
          <li>
            <HashLink
              smooth
              to="/#feature"
              style={{
                color: "#000", // 设置文字颜色
                textDecoration: "none", // 去掉默认的下划线
              }}
            >
              Feature
            </HashLink>
          </li>
          <li onClick={() => route("About")}>About</li>
          <li>
            <HashLink
              smooth
              to="/#pricing"
              style={{
                color: "#000", // 设置文字颜色
                textDecoration: "none", // 去掉默认的下划线
              }}
            >
              Pricing
            </HashLink>
          </li>
          <li onClick={() => route("Contact")}>Contact</li>
        </div>
        <div className="try-free" onClick={() => route("Login")}>
          Try Free
        </div>
        <div className="mobile-btn">
          <HamburgerIcon size={30} onClick={MenuShow} />
        </div>
      </div>
      {IsMenu && (
        <div className="menu-mask">
          <div className="menu-content">
            <div className="head">
              Sun Never Sets
              <CloseIcon size={20} className="close" onClick={MenuShow} />
            </div>
            <div className="ul" onClick={MenuShow}>
              <li onClick={() => route("")}>Home</li>
              <li>
                <HashLink
                  smooth
                  to="/#feature"
                  style={{
                    color: "#000", // 设置文字颜色
                    textDecoration: "none", // 去掉默认的下划线
                  }}
                >
                  Feature
                </HashLink>
              </li>
              <li onClick={() => route("About")}>About</li>
              <li>
                <HashLink
                  smooth
                  to="/#pricing"
                  style={{
                    color: "#000", // 设置文字颜色
                    textDecoration: "none", // 去掉默认的下划线
                  }}
                >
                  Pricing
                </HashLink>
              </li>
              <li onClick={() => route("Contact")}>Contact</li>
            </div>
            <div className="try-free" onClick={() => route("Login")}>
              Try Free
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
});

export default HeaderBar;
