import React from "react";

const AllowDown = ({
  viewBox = " 0 0 24 24",
  size = 24,
  color = "#666",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="190544"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M88.3712 346.0608a44.9024 44.9024 0 0 1-13.5168-32.512c0-26.0096 19.456-45.5168 45.5168-45.5168 12.4928 0 23.9616 5.0176 32.4608 13.0048l355.9936 364.032 356.0448-364.032a48.4352 48.4352 0 0 1 32.4608-13.0048c26.0096 0 46.0288 19.5072 46.0288 45.5168a44.9024 44.9024 0 0 1-13.5168 32.512l-387.4816 395.9808a43.9296 43.9296 0 0 1-33.536 15.0016 46.6432 46.6432 0 0 1-33.4848-14.4896L88.3712 346.0608z"
      fill={color}
      p-id="190545"
    ></path>
  </svg>
);

export default AllowDown;
