import React, { useState, useEffect } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

import "../../style/common/map.scss";
import { getRandomInt } from "../../utils/RamdomMath";

import AddIcon from "../../icon/MapAddScale";
import SubIcon from "../../icon/SubScale";

import { geoMercator } from "d3-geo"; // 导入 d3 的 geoMercator 投影
const geoUrl = "/maps/countries-110m.json"; // 地图数据路径

const Map = () => {
  const [highlightedRegion, setHighlightedRegion] = useState(null);
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [center, setCenter] = useState([10, 10]); // 初始中心点 (经度, 纬度)
  const [scale, setScale] = useState(160); // 初始缩放比例
  const [markers, setMarkers] = useState([
    {
      name: "USA (Central)",
      code: "US-C", // 美国
      coordinates: [-95.7129, 37.0902],
      user: getRandomInt(10, 20),
    },
    {
      name: "USA (West)",
      code: "US-W", // 美国
      coordinates: [-120.7129, 37.0902],
      user: getRandomInt(10, 20),
    },
    {
      name: "USA (East)",
      code: "US-E", // 美国
      coordinates: [-80.7129, 37.0902],
      user: getRandomInt(10, 20),
    },
    {
      name: "United Kingdom",
      code: "GB", // 英国
      coordinates: [-3.436, 55.3781],
      user: getRandomInt(10, 20),
    },
    {
      name: "Japan",
      code: "JP", // 日本
      coordinates: [138.2529, 36.2048],
      user: getRandomInt(10, 20),
    },
    {
      name: "Ukraine",
      code: "UA", // 乌克兰
      coordinates: [31.1656, 48.3794],
      user: getRandomInt(10, 20),
    },
    {
      name: "France",
      code: "FR", // 法国
      coordinates: [1.8883, 46.6034],
      user: getRandomInt(10, 20),
    },
    {
      name: "Germany",
      code: "DE", // 德国
      coordinates: [10.4515, 51.1657],
      user: getRandomInt(10, 20),
    },
    {
      name: "Estonia",
      code: "EE", // 爱沙尼亚
      coordinates: [25.0136, 58.5953],
      user: getRandomInt(10, 20),
    },
    {
      name: "Latvia",
      code: "LV", // 拉脱维亚
      coordinates: [24.6032, 56.8796],
      user: getRandomInt(10, 20),
    },
    {
      name: "Ireland",
      code: "IE", // 爱尔兰
      coordinates: [-7.6921, 53.1424],
      user: getRandomInt(10, 20),
    },
    {
      name: "Poland",
      code: "PL", // 波兰
      coordinates: [19.1451, 51.9194],
      user: getRandomInt(10, 20),
    },
    {
      name: "Denmark",
      code: "DK", // 丹麦
      coordinates: [9.5018, 56.2639],
      user: getRandomInt(10, 20),
    },
    {
      name: "Italy",
      code: "IT", // 意大利
      coordinates: [13.5674, 41.8719],
      user: getRandomInt(10, 20),
    },
    {
      name: "Switzerland",
      code: "CH", // 瑞士
      coordinates: [8.2275, 46.8182],
      user: getRandomInt(10, 20),
    },
    {
      name: "Australia",
      code: "AU", // 澳大利亚
      coordinates: [133.7751, -25.2744],
      user: getRandomInt(10, 20),
    },
    {
      name: "Turkey",
      code: "TR", // 土耳其
      coordinates: [35.2433, 38.9637],
      user: getRandomInt(10, 20),
    },
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMarkers((prevMarkers) => {
        const randomIndex = getRandomInt(0, prevMarkers.length);
        const newMarkers = [...prevMarkers]; // 创建新数组，避免直接修改原状态
        newMarkers[randomIndex].user = getRandomInt(10, 20);
        return newMarkers;
      });
    }, 5000);

    // 清除定时器
    return () => clearInterval(interval);
  }, []);

  const handleMouseEnter = (geo, e) => {
    setHighlightedRegion(geo.properties.name);
    setTooltipContent(geo.properties.name);
    setTooltipPosition({ x: 70, y: 20 });
  };

  const handleMouseLeave = () => {
    setHighlightedRegion(null);
    setTooltipContent("");
  };

  // 调整地图缩放和中心
  const handleMapScale = (type) => {
    const newScale = type === "add" ? scale * 1.2 : scale / 1.2; // 缩放比例调整
    setScale(newScale);
    newScale < 100 && setCenter([0, 0]);
  };

  const scrollToScale = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // 调整缩放比例（滚轮向上放大，向下缩小）
    const zoomFactor = e.deltaY > 0 ? 0.8 : 1.2;
    const newScale = Math.max(50, Math.min(scale * zoomFactor, 1000)); // 限制缩放范围
    setScale(newScale);
    setCenter([10, 40]);
  };

  const clickToSetCent = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const containerRect = e.target.getBoundingClientRect();
    const mouseX = e.clientX - containerRect.left;
    const mouseY = e.clientY - containerRect.top;
    const [geoX, geoY] = projection.invert([mouseX, mouseY]);
    const newCenter = [
      geoX - (geoX - center[0]) / 1,
      geoY - (geoY - center[1]) / 1,
    ];
    setCenter(newCenter);
  };

  const projection = geoMercator() // 定义投影
    .center(center) // 设置中心点
    .scale(scale) // 设置缩放比例
    .translate([400, 250]); // 偏移以居中显示地图（宽高根据容器调整）

  return (
    <React.Fragment>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
        onWheel={(e) => {
          e.preventDefault();
          e.stopPropagation();
          scrollToScale(e); // 调用缩放逻辑
        }}
      >
        <div className="add" onClick={(e) => handleMapScale("add")}>
          <AddIcon size="25" color="#fff" />
        </div>
        <div className="sub" onClick={(e) => handleMapScale("sub")}>
          <SubIcon size="22" color="#fff" />
        </div>
        <ComposableMap
          projection={projection} // 投影类型
          projectionConfig={{ scale: scale }} // 地图缩放
          // 使地图适应容器
          onClick={(e) => clickToSetCent(e)}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies
                .filter((geo) => geo.properties.name !== "Antarctica") // 直接在这里过滤
                .map((geo) => {
                  const isHighlighted =
                    geo.properties.name === highlightedRegion; // 判断是否高亮

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={(e) => handleMouseEnter(geo, e)} // 鼠标悬停时高亮区域
                      onMouseLeave={handleMouseLeave} // 鼠标离开时取消高亮
                      style={{
                        default: {
                          fill: "#EAEAEC", // 默认颜色
                          outline: "none",
                        },
                        hover: {
                          fill: isHighlighted ? "#999" : "#EAEAEC", // 高亮颜色
                          outline: "none",
                        },
                        pressed: {
                          fill: "#E42", // 按下时颜色
                          outline: "none",
                        },
                      }}
                    />
                  );
                })
            }
          </Geographies>
          {/* 渲染城市标记 */}
          {markers.map(({ name, coordinates, user, code }) => {
            return (
              <Marker key={name} coordinates={coordinates}>
                <circle
                  r={4}
                  fill="rgb(118, 137, 231)"
                  className="map-cricle"
                />
                <text textAnchor="middle" y={-8} x={0} style={{ fontSize: 7 }}>
                  {code} <tspan style={{ fill: "red" }}>({user})</tspan>
                </text>
              </Marker>
            );
          })}
        </ComposableMap>
        {/* Tooltip */}
        {tooltipContent && (
          <div
            style={{
              position: "absolute",
              top: tooltipPosition.y,
              right: tooltipPosition.x,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "#FFF",
              padding: "10px",
              borderRadius: "5px",
              pointerEvents: "none",
              fontSize: "14px",
              border: "1px",
            }}
          >
            {tooltipContent} {/* 显示高亮国家名称 */}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Map;
