import React, { Component } from "react";
import "../style/home.scss";
// import Pagination from "@material-ui/lab/Pagination";
import AddUserIcon from "../icon/AddUserIcon";
import DeleteIcon from "../icon/DeleteIcon";
import EditIcon from "../icon/EditIcon";
import * as GetUserListAPI from "../apis/GetUserListAPI";
import * as GetUserAPI from "../apis/GetUserAPI";
import Loading from "./common/Loading";
import NoContent from "../icon/NoContent";
import * as DeleteUserAPI from "../apis/DeleteUserAPI";
import { Pagination } from "antd";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import * as GetInviteCodeAPI from "../apis/GetInviteCodeAPI";
import moment from "moment";
import AlertBox from "./common/AlertBox";
import cookie from "react-cookies";
import { routePage } from "../utils/RoutePage";
import Copy from "../icon/Copy";
import CopyAlready from "../icon/CopyAlready";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      user: null,
      inviteCode: "",
      deleteId: "",
      usertype: null,
      setIsCopy: false,
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.getUser = this.getUser.bind(this);
    this.handlePreviewAlert = this.handlePreviewAlert.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
  }

  alertRef = React.createRef();

  componentDidMount() {
    let param = cookie.load("token");
    if (param) {
      console.log(param);
      this.setState({ usertype: param.user.roleType });
    }
    this.getUser();
    GetInviteCodeAPI.getInviteCode().then((res) => {
      var data = res.data.data;
      this.setState({ inviteCode: data });
    });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  handleCopy = async () => {
    let { inviteCode } = this.state;
    try {
      await navigator.clipboard.writeText(inviteCode);
      this.setState({ setIsCopy: true });
      setTimeout(() => {
        this.setState({ setIsCopy: false });
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  getUser() {
    this.toggleShow();
    GetUserAPI.getUser()
      .then((res) => {
        this.toggleShow();
        var data = res.data.data;
        if (data) {
          this.setState({
            user: data,
          });
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  handlePreviewAlert(id) {
    this.handleToggle();
    this.setState({ deleteId: id });
  }

  handleToggle() {
    if (this.alertRef) {
      this.alertRef.current.toggleAlertShow();
    }
  }

  render() {
    const { user, inviteCode, usertype, setIsCopy } = this.state;
    let { history } = this.props;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <AlertBox
          ref={this.alertRef}
          {...this.props}
          confirmHandler={this.confirmHandler}
          toggleAlertShow={this.handleToggle}
        />
        <div className="home-toolbar">
          <div className="left">
            INVITE CODE : <span>{inviteCode}</span>
            {setIsCopy ? (
              <CopyAlready size={20} />
            ) : (
              <Copy size={20} className="icon" onClick={this.handleCopy} />
            )}
          </div>
        </div>
        <div className="user-page">
          <div className="head">
            <div className="left">
              <div className="name">ACCOUNT</div>
              <div className="image">AVATAR</div>
              <React.Fragment>
                <div className="password">PASSWORD</div>
                <div className="telegram">TELEGRAM</div>
                <div className="nickname">NICK NAME</div>
              </React.Fragment>

              <div className="state">STATE</div>
              <div className="time">TIME</div>
            </div>
            <div className="tool">OPERATION</div>
          </div>
          <div className="body">
            {user !== null ? (
              <React.Fragment>
                <div className="li">
                  <div className="left">
                    <div className="name">{user.username}</div>
                    <div className="image">
                      {user.image !== null ? (
                        <div className="img">
                          <img src={BASE_URL + user.image} alt="" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <React.Fragment>
                      <div className="password">{user.password}</div>
                      <div className="telegram">{user.telegramId}</div>
                      <div className="nickname">{user.nickname}</div>
                    </React.Fragment>
                    <div className={`state ${user.state > 0 ? "on" : "off"}`}>
                      {user.state > 0 ? "ON" : "OFF"}
                    </div>
                    <div className="time">
                      {moment(user.createtime * 1000).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </div>
                  </div>
                  <div className="tool">
                    <EditIcon
                      size={20}
                      className="icon"
                      color="#285db1"
                      onClick={() => routePage(history, "AddUser", user.id)}
                    />
                  </div>
                </div>
                <div className="mobile-li">
                  <div className="row">
                    <div className="left">
                      {user.image !== null ? (
                        <div className="img">
                          <img src={BASE_URL + user.image} alt="" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="right">
                      <div className="r-row">
                        <div className="title">ACCOUNT</div> <i>:</i>
                        <div className="value">{user.username}</div>
                      </div>
                      <div className="r-row">
                        <div className="title">PASSWORD</div>
                        <i>:</i>
                        <div className="value">{user.password}</div>
                      </div>
                      <div className="r-row">
                        <div className="title">TELEGRAM</div>
                        <i>:</i>
                        <div className="value">{user.telegramId}</div>
                      </div>
                      <div className="r-row">
                        <div className="title">TIME</div>
                        <i>:</i>
                        <div className="value">
                          {moment(user.createtime * 1000).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                      </div>
                      <div className="r-row">
                        <div className="title">STATE </div>
                        <i>:</i>
                        <div className="value">
                          <div
                            className={`state ${
                              user.state > 0 ? "on" : "off"
                            }`}>
                            {user.state > 0 ? "ON" : "OFF"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btn">
                      <EditIcon
                        size={25}
                        className="icon"
                        color="#285db1"
                        onClick={() => routePage(history, "AddUser", user.id)}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div className="no-content">
                <NoContent size={200} />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
