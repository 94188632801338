import React from "react";

const CopyAlready = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#2fb237",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="196310"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M594.670933 543.744a37.546667 37.546667 0 1 0-56.1152-49.834667L384.546133 667.101867l-62.941866-70.792534a37.546667 37.546667 0 1 0-56.1152 49.834667l90.999466 102.4a37.546667 37.546667 0 0 0 56.1152 0l182.0672-204.8z"
      fill={color}
      p-id="196311"
    ></path>
    <path
      d="M263.9872 191.146667v30.72H204.8A139.946667 139.946667 0 0 0 64.853333 361.813333v512a139.946667 139.946667 0 0 0 139.946667 139.946667h432.3328a139.946667 139.946667 0 0 0 139.946667-139.946667v-30.72h57.002666a139.946667 139.946667 0 0 0 139.946667-139.4688l1.8432-511.931733a139.946667 139.946667 0 0 0-139.946667-140.424533H403.933867a139.946667 139.946667 0 0 0-139.946667 139.946666z m139.946667-64.853334h431.991466a64.853333 64.853333 0 0 1 64.853334 65.1264l-1.911467 511.931734a64.853333 64.853333 0 0 1-64.853333 64.648533h-56.9344V361.813333a139.946667 139.946667 0 0 0-139.946667-139.946666H339.080533V191.146667a64.853333 64.853333 0 0 1 64.853334-64.853334zM204.8 296.96h432.3328a64.853333 64.853333 0 0 1 64.853333 64.853333v512a64.853333 64.853333 0 0 1-64.853333 64.853334H204.8a64.853333 64.853333 0 0 1-64.853333-64.853334V361.813333a64.853333 64.853333 0 0 1 64.853333-64.853333z"
      fill={color}
      p-id="196312"
    ></path>
  </svg>
);

export default CopyAlready;