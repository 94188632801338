import React from "react";

const Card = ({
    viewBox = '0 0 24 24',
    size = 24,
    color ="#fff",
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="48973"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M952 896H72c-39.7 0-72-32.3-72-72V200c0-39.7 32.3-72 72-72h880c39.7 0 72 32.3 72 72v624c0 39.7-32.3 72-72 72zM72 200v624h880V200H72z"
      p-id="48974"
      fill={color}
    ></path>
    <path
      d="M546 418H166c-19.9 0-36-16.1-36-36s16.1-36 36-36h380c19.9 0 36 16.1 36 36s-16.1 36-36 36zM858 673H166c-19.9 0-36-16.1-36-36s16.1-36 36-36h692c19.9 0 36 16.1 36 36s-16.1 36-36 36z"
      p-id="48975"
      fill={color}
    ></path>
  </svg>
);


export default Card;