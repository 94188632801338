import React, { useEffect, useLayoutEffect } from "react";
import "../../style/common/footerBar.scss";
import footerImg from "../../image/footer-bg.png";
import { HashLink } from "react-router-hash-link";

const FooterBar = React.forwardRef((props, ref) => {
  const routePage = (type) => {
    props.history.push(`/${type}`);
  };
  const location = props.location;

  useEffect(() => {
    // 在 DOM 更新后异步执行
    const hash = location.hash;
    let timer = null;
    if (hash) {
      timer = setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [location]);

  useLayoutEffect(() => {
    // 在浏览器绘制之前同步执行，
    // 可以确保所有 DOM 更新完成后再进行滚动，解决了元素未渲染的问题。
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <React.Fragment>
      <div className="footerBar">
        <img src={footerImg} alt="" />
        <div className="footer">
          <div className="logo" onClick={() => routePage("")}>
            Sun Nerver Sets
          </div>
          <div className="ul">
            <li onClick={() => routePage("")}>Home</li>
            <li>
              <HashLink
                smooth
                to="/#feature"
                style={{
                  color: "#000", // 设置文字颜色
                  textDecoration: "none", // 去掉默认的下划线
                }}
              >
                Feature
              </HashLink>
            </li>
            <li onClick={() => routePage("About")}>About</li>
            <li>
              <HashLink
                smooth
                to="/#pricing"
                style={{
                  color: "#000", // 设置文字颜色
                  textDecoration: "none", // 去掉默认的下划线
                }}
              >
                Pricing
              </HashLink>
            </li>
            <li onClick={() => routePage("Contact")}>Contact</li>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

export default FooterBar;
