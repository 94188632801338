import React, { Component } from "react";
import "../style/sku.scss";
// import Pagination from "@material-ui/lab/Pagination";
import AddUserIcon from "../icon/AddUserIcon";
import DeleteIcon from "../icon/DeleteIcon";
import EditIcon from "../icon/EditIcon";
import * as GetSkuListAPI from "../apis/GetSkuListAPI";
import Loading from "./common/Loading";
import NoContent from "../icon/NoContent";
import * as DeleteSkuAPI from "../apis/DeleteSkuAPI";
import { Pagination } from "antd";
import SkuIcon from "../icon/Sku";
import { BASE_URL } from "../constants/ApiEndpointConstants";

import AlertBox from "./common/AlertBox";
import moment from "moment";
import { routePage } from "../utils/RoutePage";

class Sku extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      page: 1,
      per_page: 10,
      count: 0,
      skuList: [],
      deleteId: "",
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.getSkuList = this.getSkuList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handlePreviewAlert = this.handlePreviewAlert.bind(this);
    this.confirmHandler = this.confirmHandler.bind(this);
  }

  alertRef = React.createRef();

  componentDidMount() {
    this.getSkuList(this.state.page, this.state.per_page);
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getSkuList(page, per_page) {
    this.toggleShow();
    GetSkuListAPI.getSkuList(page, per_page)
      .then((res) => {
        this.toggleShow();
        var data = res.data.data;
        if (data) {
          var total = data.total;
          console.log(data.list);
          let count = Math.ceil(total / per_page);
          this.setState({
            skuList: data.list,
            count,
          });
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  handlePageChange(e, page, pageSize) {
    // 确保 page 和 pageSize 是数字
    const currentPage = parseInt(page, 10);
    const currentPerPage = parseInt(pageSize, 10);

    if (isNaN(currentPage) || isNaN(currentPerPage)) {
      console.error("Invalid page or pageSize");
      return;
    }
    this.setState({ page: currentPage, per_page: currentPerPage }, () => {
      this.getSkuList(currentPage, currentPerPage);
    });
  }

  handlePreviewAlert(id) {
    this.handleToggle();
    this.setState({ deleteId: id });
  }

  handleToggle() {
    if (this.alertRef) {
      this.alertRef.current.toggleAlertShow();
    }
  }
  confirmHandler() {
    this.toggleShow();
    DeleteSkuAPI.deleteSku(this.state.deleteId)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        if (code === 1) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Delete sku successfully",
            })
          );
          this.getSkuList(this.state.page, this.state.per_page);
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  render() {
    const { count, page, skuList } = this.state;
    let { history } = this.props;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <AlertBox
          ref={this.alertRef}
          {...this.props}
          confirmHandler={this.confirmHandler}
          toggleAlertShow={this.handleToggle}
        />
        <div className="sku-toolbar">
          <div className="logo" onClick={() => routePage(history, "AddSku")}>
            <SkuIcon size={24} color="#fff" className="icon" />
          </div>
        </div>
        <div className="sku-page">
          <div className="head">
            <div className="left">
              <div className="name">NAME</div>
              <div className="icon">ICON</div>
              <div className="currency">CURRENCY</div>
              <div className="sell">SELL</div>
              <div className="purchase">PURCHASE</div>
              <div className="state">STATUS</div>
              <div className="time">TIME</div>
            </div>
            <div className="tool">OPERATION</div>
          </div>
          <div className="body">
            {skuList.length > 0 ? (
              <React.Fragment>
                {skuList.map((item, index) => {
                  return (
                    <React.Fragment>
                      <div className="li" key={index}>
                        <div className="left">
                          <div className="name">{item.name}</div>
                          <div className="icon">
                            <img src={BASE_URL + item.icon} alt="" />
                          </div>
                          <div className="currency">{item.currency}</div>
                          <div className="sell">{item.price}</div>
                          <div className="purchase">{item.costprice}</div>
                          <div
                            className={`state ${
                              item.status > 0 ? "on" : "off"
                            }`}
                          >
                            {item.status > 0 ? "ON" : "OFF"}
                          </div>
                          <div className="time">
                            {moment(item.createtime * 1000).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </div>
                        </div>
                        <div className="tool">
                          <EditIcon
                            size={25}
                            className="icon"
                            color="#285db1"
                            onClick={() =>
                              routePage(history, "AddSku", item.id)
                            }
                          />
                          <DeleteIcon
                            size={25}
                            className="icon"
                            color="#285db1"
                            onClick={() => this.handlePreviewAlert(item.id)}
                          />
                        </div>
                      </div>
                      <div className="mobile-li">
                        <div className="row">
                          <div className="left">
                            <div className="img">
                              <img src={BASE_URL + item.icon} alt="" />
                            </div>
                          </div>
                          <div className="right">
                            <div className="r-row">
                              <div className="title">NAME</div> <i>:</i>
                              <div className="value">{item.name}</div>
                            </div>
                            <div className="r-row">
                              <div className="title">SELL</div>
                              <i>:</i>
                              <div className="value">USD {item.price}</div>
                            </div>
                            <div className="r-row">
                              <div className="title">PURCHASE</div>
                              <i>:</i>
                              <div className="value">USD {item.costprice}</div>
                            </div>
                            <div className="r-row">
                              <div className="title">TIME</div>
                              <i>:</i>
                              <div className="value">
                                {moment(item.createtime * 1000).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </div>
                            </div>
                            <div className="r-row">
                              <div className="title">STATE </div>
                              <i>:</i>
                              <div className="value">
                                <div
                                  className={`state ${
                                    item.status > 0 ? "on" : "off"
                                  }`}
                                >
                                  {item.status > 0 ? "ON" : "OFF"}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="btn">
                            <EditIcon
                              size={25}
                              className="icon"
                              color="#285db1"
                              onClick={() =>
                                routePage(history, "AddSku", item.id)
                              }
                            />
                            <DeleteIcon
                              size={25}
                              className="icon"
                              color="#285db1"
                              onClick={() => this.handlePreviewAlert(item.id)}
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            ) : (
              <div className="no-content">
                <NoContent size={200} />
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <Pagination
            size="small"
            total={this.state.count * this.state.per_page}
            // count={count}
            pageSize={this.state.per_page}
            current={this.state.page}
            onChange={(page, pageSize) =>
              this.handlePageChange(null, page, pageSize)
            }
            showQuickJumper
            variant="outlined"
            color="standard"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Sku;
