import React from "react";
const TipIcon = ({
    size = 24,
    color = "#9fa4a6",
    viewBox = "0 0 24 24",
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="47792"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M553.106286 424.228571v292.571429h81.993143v58.514286h-234.057143v-58.514286h90.185143V471.04H424.228571V424.228571h128.877715zM512 987.428571a475.428571 475.428571 0 1 1 0-950.857142 475.428571 475.428571 0 0 1 0 950.857142z m0-73.142857a402.285714 402.285714 0 1 0 0-804.571428 402.285714 402.285714 0 0 0 0 804.571428z m0-570.514285a65.828571 65.828571 0 1 1 0-131.657143 65.828571 65.828571 0 0 1 0 131.657143z"
      fill={color}
      p-id="47793"
    ></path>
  </svg>
);
export default TipIcon;