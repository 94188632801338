import React from "react";

const Tool = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="70578"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M881.1 248.4H749.3v-79.1c0-42.2-36.9-79.1-79.1-79.1H353.8c-42.2 0-79.1 36.9-79.1 79.1v79.1H142.9c-42.2 0-79.1 36.9-79.1 79.1v527.2c0 42.2 36.9 79.1 79.1 79.1H881c42.2 0 79.1-36.9 79.1-79.1V327.5c0-42.2-36.9-79.1-79-79.1z m-553.6-79.1c0-15.8 10.5-26.4 26.4-26.4h316.3c15.8 0 26.4 10.5 26.4 26.4v79.1H327.5v-79.1z m579.9 685.4c0 15.8-10.5 26.4-26.4 26.4H142.9c-15.8 0-26.4-10.5-26.4-26.4V327.5c0-15.8 10.5-26.4 26.4-26.4H881c15.8 0 26.4 10.5 26.4 26.4v527.2z"
      fill={color}
      p-id="70579"
    ></path>
    <path
      d="M670.2 564.7H538.4V432.9c0-15.8-10.5-26.4-26.4-26.4-15.8 0-26.4 10.5-26.4 26.4v131.8H353.8c-15.8 0-26.4 10.5-26.4 26.4 0 15.8 10.5 26.4 26.4 26.4h131.8v131.8c0 15.8 10.5 26.4 26.4 26.4 15.8 0 26.4-10.5 26.4-26.4V617.5h131.8c15.8 0 26.4-10.5 26.4-26.4-0.1-15.8-10.6-26.4-26.4-26.4z"
      fill={color}
      p-id="70580"
    ></path>
  </svg>
);

export default Tool;
