import React, { Component } from "react";
import CloseIcon from "../icon/CloseIcon";
import "../style/editOrder.scss";
import Loading from "./common/Loading";
import Submit from "../icon/SubmitIcon";
import * as SubmitOrderAPI from "../apis/SubmitOrderAPI";
import * as GetOrderAPI from "../apis/GetOrderAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";

import FileIcon from "../icon/FileIcon";
import { upload } from "../utils/UploadFile";
import cookie from "react-cookies";
import { img } from "@douyinfe/semi-ui/lib/es/markdownRender/components";
import { Select } from "antd";
import ReactFlagsSelect from "react-flags-select";
import { foundCountry } from "../utils/CountryMap";
import { setAPIAuthorizationToken } from "../apis/API";
import moment from "moment";
import AlertBox from "./common/AlertBox";
import ReactPhoneInput from "react-telephone-input/lib/withStyles";
import flagImg from "../image/flag.png";
import * as FormValidation from "../utils/FormValidation";
import { getId } from "../utils/RoutePage";

class EditOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      countrycode: null,
      country: "",
      id: 0,
      isClick: true,
      orderDetail: {},
      roleType: null,
      description: "",
      descriptionNew: "",
      type: "",
      refund: "",
      request: "",
      staffRequset: "",
    };
    this.handlePreviewAlert = this.handlePreviewAlert.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.back = this.back.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.confirmHandler = this.confirmHandler.bind(this);
    this.changeRequest = this.changeRequest.bind(this);
    this.blurInput = this.blurInput.bind(this);
  }

  alertRef = React.createRef();

  componentDidMount() {
    let param = cookie.load("token");
    setAPIAuthorizationToken(param.token);
    let { location } = this.props;
    let id = getId(location, "EditOrder");
    if (id) {
      this.toggleShow();
      GetOrderAPI.getOrder(id)
        .then((res) => {
          this.toggleShow();
          var data = res.data.data;
          if (data) {
            if (data.xuqiumoban && data.xuqiumoban !== "") {
              let parsedResult = this.format(data.xuqiumoban);
              this.setState({ request: parsedResult });
            }
            if (data.yuangongxuqiumoban && data.yuangongxuqiumoban !== "") {
              let parsedResult = this.format(data.yuangongxuqiumoban);
              this.setState({ staffRequset: parsedResult });
            }
            console.log(data);
            this.setState({
              id: parseInt(id),
              countrycode: data.countrycode,
              status: data.status,
              isClick: data.status > 0 ? true : false,
              country: foundCountry(data.countrycode),
              orderDetail: data,
              roleType: param.user.roleType,
            });
          }
        })
        .catch((err) => {
          this.toggleShow();
        });
    }
  }

  format(str) {
    const result = str.split("\n");
    const parsedResult = result.map((item) => {
      const [key, value] = item.split(":");
      if (value && value.includes("{", "}")) {
        console.log(value);
        return {
          title: key.trim(),
          value: "",
          isValid: null,
        };
      }
      return {
        title: key.trim(),
        value: value ? value.trim() : "",
        isValid: null,
      };
    });
    console.log(parsedResult);
    return parsedResult;
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  handlePreviewAlert(type) {
    this.handleToggle();
    this.setState({ type });
  }

  handleToggle() {
    if (this.alertRef) {
      this.alertRef.current.toggleAlertShow();
    }
  }

  confirmHandler() {
    let {
      orderDetail,
      id,
      description,
      descriptionNew,
      refund,
      request,
      staffRequset,
      type,
      roleType,
    } = this.state;

    let xuqiumoban = orderDetail.xuqiumoban;
    let yuangongxuqiumoban = orderDetail.yuangongxuqiumoban;
    if (roleType === "staff") {
      // 匹配替换value
      staffRequset &&
        staffRequset.forEach((item) => {
          const regex = new RegExp(`{${item.title}}`, "g");
          yuangongxuqiumoban = yuangongxuqiumoban.replace(regex, item.value);
        });
    }
    this.toggleShow();
    let param = {
      id: id,
      description: orderDetail.description + "\n" + descriptionNew,
      status: type,
      productskurelationid: orderDetail.productskurelationid,
      amount: refund,
      xuqiumoban: xuqiumoban,
      yuangongxuqiumoban: yuangongxuqiumoban,
    };
    console.log(param);
    SubmitOrderAPI.submitOrder(param)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        console.log(msg);
        if (code === 1) {
          if (this.state.id !== 0) {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "Modify country successfully",
              })
            );
            this.back();
          } else {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "Add country Successfully",
              })
            );
            this.back();
          }
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  changeRequest(e, type, index) {
    let { staffRequset } = this.state;
    if (type === "phone") {
      let telephone = e.replace("-", "").replace(" ", "");
      staffRequset[index].value = telephone;
    } else {
      staffRequset[index].value = e.target.value;
    }
    staffRequset[index].isValid = null;
    this.setState({ staffRequset });
  }

  blurInput(e, type, index) {
    let reg;
    let { staffRequset } = this.state;
    switch (type) {
      case "email":
        if (!FormValidation.isValidEmail(e.target.value)) {
          staffRequset[index].isValid = true;
        } else {
          staffRequset[index].isValid = false;
        }
        break;

      case "zip":
        reg = /^[a-zA-Z0-9-_]{4,30}$/;
        if (!reg.test(e.target.value)) {
          staffRequset[index].isValid = true;
        } else {
          staffRequset[index].isValid = false;
        }
        break;
      case "phone":
        if (FormValidation.isValidPhoneNumber(e)) {
          staffRequset[index].isValid = false;
        } else {
          staffRequset[index].isValid = true;
        }
        break;

      default:
        break;
    }
    this.setState({ staffRequset });
  }

  changeInput(e, type) {
    let { orderDetail, descriptionNew, refund } = this.state;
    switch (type) {
      case "textarea":
        this.setState({ descriptionNew: e.target.value });
        break;
      case "refund":
        this.setState({ refund: e.target.value });
        break;
      default:
        break;
    }
  }

  render() {
    const {
      orderDetail,
      roleType,
      description,
      refund,
      descriptionNew,
      request,
      staffRequset,
    } = this.state;
    console.log(staffRequset);

    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <AlertBox
          ref={this.alertRef}
          {...this.props}
          confirmHandler={this.confirmHandler}
          toggleAlertShow={this.handleToggle}
        />
        <div className="editOrder-page">
          <CloseIcon
            size={30}
            className="icon close"
            color="#5864ff"
            onClick={this.back}
          />
          <div
            className={`type ${
              orderDetail.menuname && orderDetail.menuname.toLowerCase()
            }`}>
            {orderDetail.menuname}
          </div>
          <div className="row">
            <div className="title-row">
              <div className="title">COMPANY </div>
              <div className="value">
                {orderDetail.productname}
                <img src={BASE_URL + orderDetail.producticon} alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="title-row">
              <div className="title">SKU </div>
              <div className="value">
                ({orderDetail.skuname})
                <img src={BASE_URL + orderDetail.icon} alt="" /> USD{" "}
                {orderDetail.price}{" "}
                {orderDetail.fujiawenzi !== "" && `+ ${orderDetail.fujiawenzi}`}
              </div>
            </div>
          </div>

          {request &&
            request.map((item, index) => {
              return (
                <div className="row" key={index}>
                  <div className="title-row">
                    <div className="title">{item.title}</div>
                    <div className="input-value">{item.value}</div>
                  </div>
                </div>
              );
            })}
          {staffRequset &&
            staffRequset.map((item, index) => {
              return (
                <div className="row" key={index}>
                  <div className="title-row">
                    <div className="title">{item.title}</div>
                    <div className="input-value">
                      {roleType === "staff" ? (
                        orderDetail.yuangongxuqiumoban &&
                        orderDetail.yuangongxuqiumoban.includes("{", "}") ? (
                          <React.Fragment>
                            {item.title === "phone" ? (
                              <ReactPhoneInput
                                defaultCountry="us"
                                flagsImagePath={flagImg}
                                onChange={(e) =>
                                  this.changeRequest(
                                    e,
                                    item.title.toLowerCase(),
                                    index
                                  )
                                }
                                value={item.value}
                                className={`${item.isValid ? "err" : ""}`}
                                onBlur={(e) =>
                                  this.blurInput(
                                    e,
                                    item.title.toLowerCase(),
                                    index
                                  )
                                }
                              />
                            ) : (
                              <input
                                type="text"
                                onChange={(e) =>
                                  this.changeRequest(
                                    e,
                                    item.title.toLowerCase(),
                                    index
                                  )
                                }
                                value={item.value}
                                placeholder={`Please enter your ${item.title}`}
                                className={`${item.isValid ? "err" : ""}`}
                                onBlur={(e) =>
                                  this.blurInput(
                                    e,
                                    item.title.toLowerCase(),
                                    index
                                  )
                                }
                              />
                            )}
                            {item.isValid && <span>Invalid {item.title}</span>}
                          </React.Fragment>
                        ) : (
                          item.value
                        )
                      ) : (
                        item.value
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          {roleType === "admin" && (
            <div className="row">
              <div className="title-row">
                <div className="title">REFUND PRICE </div>
                <input
                  type="number"
                  min={0}
                  value={refund || ""}
                  placeholder="If you want the audit to fail, fill in the refund amount"
                  onChange={(e) => this.changeInput(e, "refund")}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="title-row">
              <div className="title">DESCRIPTION</div>
              <textarea
                value={description + descriptionNew || ""}
                placeholder={orderDetail.description}
                onChange={(e) => this.changeInput(e, "textarea")}></textarea>
            </div>
          </div>
          <div className="row">
            <div className="title-row">
              <div className="title">TOTAL PRICE </div>
              <div className="value">
                {orderDetail.currency} {orderDetail.price}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="title-row">
              <div className="title">CREATE TIME </div>
              <div className="value">
                {" "}
                {moment(orderDetail.createtime * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
              </div>
            </div>
          </div>
          <div className="footer">
            {roleType === "admin" && (
              <React.Fragment>
                <div
                  className="btn fail"
                  onClick={() => this.handlePreviewAlert("failed")}>
                  Submit for fail{" "}
                  <Submit size={15} color="#fff" className="icon" />
                </div>
                <div
                  className="btn success"
                  onClick={() => this.handlePreviewAlert("successed")}>
                  Submit for success{" "}
                  <Submit size={15} color="#fff" className="icon" />
                </div>
              </React.Fragment>
            )}
            {roleType === "staff" && (
              <div
                className="btn"
                onClick={() =>
                  this.handlePreviewAlert(
                    orderDetail.status === "pending"
                      ? "processing"
                      : orderDetail.status
                  )
                }>
                Submit for review{" "}
                <Submit size={15} color="#fff" className="icon" />
              </div>
            )}
            {roleType === "client" && (
              <div
                className="btn"
                onClick={() => this.handlePreviewAlert(orderDetail.status)}>
                Submit
                <Submit size={15} color="#fff" className="icon" />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EditOrder;
