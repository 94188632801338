import React from "react";

const Design = ({
  viewBox = "0 0 24 24",
  color = "#fff",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="4465"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M917.333333 1024 618.666667 1024c-11.797333 0-21.333333-9.557333-21.333333-21.333333L597.333333 21.333333c0-11.776 9.536-21.333333 21.333333-21.333333l298.666667 0c11.797333 0 21.333333 9.557333 21.333333 21.333333l0 981.333333C938.666667 1014.442667 929.130667 1024 917.333333 1024zM640 981.333333l256 0L896 42.666667 640 42.666667 640 981.333333z"
      p-id="4466"
      fill={color}
    ></path>
    <path
      d="M618.666667 170.666667l106.666667 0 0 42.666667-106.666667 0 0-42.666667Z"
      p-id="4467"
      fill={color}
    ></path>
    <path
      d="M618.666667 298.666667l106.666667 0 0 42.666667-106.666667 0 0-42.666667Z"
      p-id="4468"
      fill={color}
    ></path>
    <path
      d="M618.666667 426.666667l106.666667 0 0 42.666667-106.666667 0 0-42.666667Z"
      p-id="4469"
      fill={color}
    ></path>
    <path
      d="M618.666667 554.666667l106.666667 0 0 42.666667-106.666667 0 0-42.666667Z"
      p-id="4470"
      fill={color}
    ></path>
    <path
      d="M618.666667 682.666667l106.666667 0 0 42.666667-106.666667 0 0-42.666667Z"
      p-id="4471"
      fill={color}
    ></path>
    <path
      d="M618.666667 810.666667l106.666667 0 0 42.666667-106.666667 0 0-42.666667Z"
      p-id="4472"
      fill={color}
    ></path>
    <path
      d="M320 981.333333l-85.333333 0c-58.816 0-106.666667-47.850667-106.666667-106.666667L128 256c0-4.202667 1.258667-8.341333 3.584-11.84l128-192c7.914667-11.882667 27.584-11.882667 35.498667 0l128 192C425.408 247.658667 426.666667 251.797333 426.666667 256l0 618.666667C426.666667 933.482667 378.816 981.333333 320 981.333333zM170.666667 262.464 170.666667 874.666667c0 35.285333 28.714667 64 64 64l85.333333 0c35.285333 0 64-28.714667 64-64L384 262.464l-106.666667-160L170.666667 262.464z"
      p-id="4473"
      fill={color}
    ></path>
    <path
      d="M405.333333 853.333333 149.333333 853.333333c-11.797333 0-21.333333-9.557333-21.333333-21.333333s9.536-21.333333 21.333333-21.333333l256 0c11.797333 0 21.333333 9.557333 21.333333 21.333333S417.130667 853.333333 405.333333 853.333333z"
      p-id="4474"
      fill={color}
    ></path>
    <path
      d="M405.333333 768 149.333333 768c-11.797333 0-21.333333-9.557333-21.333333-21.333333s9.536-21.333333 21.333333-21.333333l256 0c11.797333 0 21.333333 9.557333 21.333333 21.333333S417.130667 768 405.333333 768z"
      p-id="4475"
      fill={color}
    ></path>
    <path
      d="M234.666667 768c-11.797333 0-21.333333-9.557333-21.333333-21.333333L213.333333 341.333333c0-11.776 9.536-21.333333 21.333333-21.333333s21.333333 9.557333 21.333333 21.333333l0 405.333333C256 758.442667 246.464 768 234.666667 768z"
      p-id="4476"
      fill={color}
    ></path>
    <path
      d="M320 768c-11.797333 0-21.333333-9.557333-21.333333-21.333333L298.666667 341.333333c0-11.776 9.536-21.333333 21.333333-21.333333s21.333333 9.557333 21.333333 21.333333l0 405.333333C341.333333 758.442667 331.797333 768 320 768z"
      p-id="4477"
      fill={color}
    ></path>
    <path
      d="M213.333333 149.333333l128 0 0 42.666667-128 0 0-42.666667Z"
      p-id="4478"
      fill={color}
    ></path>
    <path
      d="M213.333333 362.666667c-47.061333 0-85.333333-38.272-85.333333-85.333333 0-11.776 9.536-21.333333 21.333333-21.333333s21.333333 9.557333 21.333333 21.333333c0 23.530667 19.136 42.666667 42.666667 42.666667s42.666667-19.136 42.666667-42.666667c0-11.776 9.536-21.333333 21.333333-21.333333s21.333333 9.557333 21.333333 21.333333C298.666667 324.394667 260.394667 362.666667 213.333333 362.666667z"
      p-id="4479"
      fill={color}
    ></path>
    <path
      d="M341.333333 362.666667c-47.061333 0-85.333333-38.272-85.333333-85.333333 0-11.776 9.536-21.333333 21.333333-21.333333s21.333333 9.557333 21.333333 21.333333c0 23.530667 19.136 42.666667 42.666667 42.666667s42.666667-19.136 42.666667-42.666667c0-11.776 9.536-21.333333 21.333333-21.333333s21.333333 9.557333 21.333333 21.333333C426.666667 324.394667 388.394667 362.666667 341.333333 362.666667z"
      p-id="4480"
      fill={color}
    ></path>
  </svg>
);


export default Design;