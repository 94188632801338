import React from "react";

const Email = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="65802"
    width={size}
    height={size}
    {...props}
  >
    <path d="M510.987439 550.676881" fill={color} p-id="65803"></path>
    <path
      d="M873.137549 191.07685 150.861428 191.07685c-48.060599 0-87.021959 38.960337-87.021959 87.021959l0 530.830881c0 48.059576 38.960337 87.020936 87.021959 87.020936l722.276121 0c48.061622 0 87.022982-38.96136 87.022982-87.020936L960.160531 278.098809C960.160531 230.03821 921.199171 191.07685 873.137549 191.07685zM648.377552 526.369266l269.15409-232.718249 0 501.872339L648.377552 526.369266zM160.960437 230.094492l698.556906 0c18.297738 0 34.605146 8.479115 45.23832 21.711488L514.221086 589.472466 119.017162 248.053516C129.580752 236.994646 144.460648 230.094492 160.960437 230.094492zM102.947162 796.361443 102.947162 288.10879c0-0.350994 0.020466-0.695848 0.026606-1.044796l273.734408 236.480946L102.969675 797.282419C102.965581 796.974403 102.947162 796.670481 102.947162 796.361443zM859.517343 854.375741 160.960437 854.375741c-16.10684 0-30.676673-6.567579-41.188074-17.165961l287.37201-287.369963 107.08797 92.514043 103.72027-89.679485 283.622616 283.623639C891.005498 847.426469 876.078531 854.375741 859.517343 854.375741z"
      fill={color}
      p-id="65804"
    ></path>
  </svg>
);

export default Email;
