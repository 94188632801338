import React from "react";

const Window = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="39652"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M873.411765 790.226824c0 34.514824-26.081882 62.885647-59.632941 66.499764l-7.288471 0.421647H217.449412a66.921412 66.921412 0 0 1-66.499765-59.632941l-0.421647-7.28847V201.185882c0-36.984471 29.936941-66.921412 66.921412-66.921411h588.980706c36.984471 0 66.921412 29.936941 66.921411 66.921411v588.980706z m-60.235294-374.844236h-602.352942v374.844236c0 2.770824 1.686588 5.12 4.096 6.144l2.590118 0.542117h588.980706a6.686118 6.686118 0 0 0 6.144-4.096l0.542118-2.590117V415.382588z m-6.686118-220.822588H217.449412a6.686118 6.686118 0 0 0-6.686118 6.686118v153.901176h602.352941V201.246118a6.686118 6.686118 0 0 0-4.096-6.144l-2.590117-0.542118z"
      fill={color}
      p-id="39653"
    ></path>
    <path
      d="M291.117176 311.657412a36.803765 36.803765 0 1 0 0-73.60753 36.803765 36.803765 0 0 0 0 73.60753zM401.588706 311.657412a36.803765 36.803765 0 1 0 0-73.60753 36.803765 36.803765 0 0 0 0 73.60753zM512 311.657412a36.803765 36.803765 0 1 0 0-73.60753 36.803765 36.803765 0 0 0 0 73.60753z"
      fill={color}
      p-id="39654"
    ></path>
  </svg>
);

export default Window;