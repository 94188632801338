import React, { Component } from "react";
import "../../style/common/leftBar.scss";
import logo from "../../image/logo.png";
import ChartIcon from "../../icon/ChartIcon";
import UserIcon from "../../icon/UserIcon";
import cookie from "react-cookies";
import NewsIcon from "../../icon/NewsIcon";
import OrderIcon from "../../icon/OrderIcon";
import HomeIcon from "../../icon/HomeIcon";
import Company from "../../icon/Company";
import Charge from "../../icon/Charge";
import Shop from "../../icon/Shop";
import Country from "../../icon/Country";
import Sku from "../../icon/Sku";
import CategoryIcon from "../../icon/CategoryIcon";
import CommentIcon from "../../icon/CommentIcon";
import VIPIcon from "../../icon/VIPIcon";
import Percent from "../../icon/Percent";
import { BASE_URL } from "../../constants/ApiEndpointConstants";
import * as GetUserAPI from "../../apis/GetUserAPI";

const iconColor = "rgb(115, 123, 139)";
const iconSize = 20;
const bounceColor = "#fff";

class LeftBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usertype: null,
      percentShow: false,
      param: null,
      user: null,
    };
    this.routePage = this.routePage.bind(this);
    this.getUser = this.getUser.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    if (param) {
      console.log(param);
      this.setState({ usertype: param.user.roleType, param });
    }
    this.getUser();
  }

  getUser() {
    GetUserAPI.getUser()
      .then((res) => {
        var data = res.data.data;
        if (data) {
          this.setState({
            user: data,
          });
        }
      })
      .catch((err) => {});
  }

  routePage(type) {
    const { history } = this.props;
    console.log(history);
    switch (type) {
      case "Order":
        this.setState({ percentShow: !this.state.percentShow });
        break;
      default:
        break;
    }
    history.push(`/Entrance/${type}`);
  }

  render() {
    let { location } = this.props;
    const { usertype, percentShow, param, user } = this.state;
    return (
      <React.Fragment>
        <div className="leftBar">
          <div className="left-box">
            <div className="head-title">
              <div className="img">
                {/* <img src={logo} alt="" /> */}
                Sun
              </div>
              <p>Sun Never Sets</p>
            </div>
            <ul className="tab-list">
              <div className="profile" onClick={() => this.routePage("Home")}>
                <div className="profile-box">
                  <div className="img">
                    {user && <img src={BASE_URL + user.image} alt="" />}
                  </div>
                  <div className="txt">Hi,{user && user.username}</div>
                </div>
              </div>
              <li
                className={
                  location.pathname === "/Entrance/Dashboard" ? "active" : ""
                }
                onClick={() => this.routePage("Dashboard")}>
                {location.pathname === "/Entrance/Dashboard" ? (
                  <HomeIcon size={iconSize} className="icon" color="#fff" />
                ) : (
                  <HomeIcon
                    size={iconSize}
                    className="icon"
                    color={iconColor}
                  />
                )}
                <p>DASHBOARD</p>
              </li>
              {usertype === "admin" && (
                <li
                  className={
                    location.pathname.indexOf("/VIP") >= 0 ||
                    location.pathname.indexOf("/AddVIP") >= 0
                      ? "active"
                      : ""
                  }
                  onClick={() => this.routePage("VIP")}>
                  {location.pathname.indexOf("/VIP") >= 0 ||
                  location.pathname.indexOf("/AddVIP") >= 0 ? (
                    <VIPIcon size={iconSize} className="icon" color="#fff" />
                  ) : (
                    <VIPIcon
                      size={iconSize}
                      className="icon"
                      color={iconColor}
                    />
                  )}
                  <p>VIP</p>
                </li>
              )}
              {(usertype === "admin" || usertype === "client") && (
                <li
                  className={
                    location.pathname.indexOf("/Product") >= 0 ? "active" : ""
                  }
                  onClick={() => this.routePage("Product")}>
                  {location.pathname.indexOf("/Product") >= 0 ? (
                    <Shop
                      size={iconSize}
                      className="icon bounce-icon"
                      color={bounceColor}
                    />
                  ) : (
                    <Shop
                      size={iconSize}
                      className="icon bounce-icon"
                      color={bounceColor}
                    />
                  )}
                  <p>SHOP</p>
                </li>
              )}
              <li
                className={
                  location.pathname.indexOf("/User") >= 0 ||
                  location.pathname.indexOf("/AddUser") >= 0
                    ? "active"
                    : ""
                }
                onClick={() => this.routePage("User")}>
                {location.pathname.indexOf("/User") >= 0 ||
                location.pathname.indexOf("/AddUser") >= 0 ? (
                  <UserIcon size={iconSize} className="icon" color="#fff" />
                ) : (
                  <UserIcon
                    size={iconSize}
                    className="icon"
                    color={iconColor}
                  />
                )}
                <p>AGENT</p>
              </li>
              {usertype === "admin" && (
                <React.Fragment>
                  {" "}
                  <li
                    className={
                      location.pathname.indexOf("/Category") >= 0 ||
                      location.pathname.indexOf("/AddCategory") >= 0
                        ? "active"
                        : ""
                    }
                    onClick={() => this.routePage("Category")}>
                    {location.pathname.indexOf("/Category") >= 0 ||
                    location.pathname.indexOf("/AddCategory") >= 0 ? (
                      <CategoryIcon
                        size={iconSize}
                        className="icon"
                        color="#fff"
                      />
                    ) : (
                      <CategoryIcon
                        size={iconSize}
                        className="icon"
                        color={iconColor}
                      />
                    )}
                    <p>CATEGORY</p>
                  </li>
                  <li
                    className={
                      location.pathname.indexOf("/Country") >= 0 ||
                      location.pathname.indexOf("/AddCountry") >= 0
                        ? "active"
                        : ""
                    }
                    onClick={() => this.routePage("Country")}>
                    {location.pathname.indexOf("/Country") >= 0 ||
                    location.pathname.indexOf("/AddCountry") >= 0 ? (
                      <Country size={iconSize} className="icon" color="#fff" />
                    ) : (
                      <Country
                        size={iconSize}
                        className="icon"
                        color={iconColor}
                      />
                    )}
                    <p>COUNTRY</p>
                  </li>
                  <li
                    className={
                      location.pathname.indexOf("/Sku") >= 0 ||
                      location.pathname.indexOf("/AddSku") >= 0
                        ? "active"
                        : ""
                    }
                    onClick={() => this.routePage("Sku")}>
                    {location.pathname.indexOf("/Sku") >= 0 ||
                    location.pathname.indexOf("/AddSku") >= 0 ? (
                      <Sku size={iconSize} className="icon" color="#fff" />
                    ) : (
                      <Sku size={iconSize} className="icon" color={iconColor} />
                    )}
                    <p>SKU</p>
                  </li>
                </React.Fragment>
              )}
              <li
                className={
                  location.pathname.indexOf("/Company") >= 0 ||
                  location.pathname.indexOf("/AddCompany") >= 0
                    ? "active"
                    : ""
                }
                onClick={() => this.routePage("Company")}>
                {location.pathname.indexOf("/Company") >= 0 ||
                location.pathname.indexOf("/AddCompany") >= 0 ? (
                  <Company size={iconSize} className="icon" color="#fff" />
                ) : (
                  <Company size={iconSize} className="icon" color={iconColor} />
                )}
                <p>COMPANY</p>
              </li>
              <li
                className={
                  location.pathname.indexOf("/Billing") >= 0 ||
                  location.pathname.indexOf("/AddBilling") >= 0
                    ? "active"
                    : ""
                }
                onClick={() => this.routePage("Billing")}>
                {location.pathname.indexOf("/Billing") >= 0 ||
                location.pathname.indexOf("/AddBilling") >= 0 ? (
                  <Charge size={iconSize} className="icon" color="#fff" />
                ) : (
                  <Charge size={iconSize} className="icon" color={iconColor} />
                )}
                <p>BILLING</p>
              </li>
              <li
                className={
                  location.pathname.indexOf("/Order") >= 0 ||
                  location.pathname.indexOf("/AddOrder") >= 0
                    ? "active"
                    : ""
                }
                onClick={() => this.routePage("Order")}>
                {location.pathname.indexOf("/Order") >= 0 ? (
                  <OrderIcon size={iconSize} className="icon" color="#fff" />
                ) : (
                  <OrderIcon
                    size={iconSize}
                    className="icon"
                    color={iconColor}
                  />
                )}
                <p>ORDER</p>
              </li>
              {percentShow && (
                <li
                  className={
                    location.pathname.indexOf("/Percent") >= 0 ? "active" : ""
                  }
                  onClick={() => this.routePage("Percent")}>
                  {location.pathname.indexOf("/Percent") >= 0 ? (
                    <Percent size={iconSize} className="icon" color="#fff" />
                  ) : (
                    <Percent
                      size={iconSize}
                      className="icon"
                      color={iconColor}
                    />
                  )}
                  <p>COMMISSION</p>
                </li>
              )}

              {usertype === "admin" && (
                <React.Fragment>
                  <li
                    className={
                      location.pathname.indexOf("/News") >= 0 ||
                      location.pathname.indexOf("/AddNews") >= 0
                        ? "active"
                        : ""
                    }
                    onClick={() => this.routePage("News")}>
                    {location.pathname.indexOf("/News") >= 0 ||
                    location.pathname.indexOf("/AddNews") >= 0 ? (
                      <NewsIcon size={iconSize} className="icon" color="#fff" />
                    ) : (
                      <NewsIcon
                        size={iconSize}
                        className="icon"
                        color={iconColor}
                      />
                    )}
                    <p>NEW</p>
                  </li>
                  <li
                    className={
                      location.pathname.indexOf("/Comment") >= 0 ||
                      location.pathname.indexOf("/AddComment") >= 0
                        ? "active"
                        : ""
                    }
                    onClick={() => this.routePage("Comment")}>
                    {location.pathname.indexOf("/Comment") >= 0 ||
                    location.pathname.indexOf("/AddComment") >= 0 ? (
                      <CommentIcon
                        size={iconSize}
                        className="icon"
                        color="#fff"
                      />
                    ) : (
                      <CommentIcon
                        size={iconSize}
                        className="icon"
                        color={iconColor}
                      />
                    )}
                    <p>COMMENT</p>
                  </li>
                </React.Fragment>
              )}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LeftBar;
