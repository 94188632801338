import React from "react";
export const routePage = (history, type, id) => {
  console.error(history, type, id);
  if (id) {
    history && history.push(`/Entrance/${type}/${id}`);
  } else {
    history && history.push(`/Entrance/${type}`);
  }
};

export const getId = (location, type) => {
  if (location.pathname.indexOf(`/Entrance/${type}/`) >= 0) {
    let id = location.pathname.replace(`/Entrance/${type}/`, "");
    return id;
  }
};
