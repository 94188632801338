import React, { Component } from "react";
// import Pagination from "@material-ui/lab/Pagination";
import DeleteIcon from "../icon/DeleteIcon";
import EditIcon from "../icon/EditIcon";
import Loading from "./common/Loading";
import NoContent from "../icon/NoContent";
import "../style/order.scss";
// import * as GetSiteListAPI from "../apis/GetSiteListAPI";
import cookie from "react-cookies";
import OrderDetail from "../icon/OrderDetail";
import * as GetOrderListAPI from "../apis/GetOrderListAPI";
import PaySuucess from "../icon/PaySuccess";
import PayError from "../icon/PayError";
import Pending from "../icon/Pending";
import moment from "moment";
import True from "../icon/True";
import False from "../icon/False";
import Select from "react-select";
import Email from "../icon/SendEmail";
import SearchIcon from "../icon/SearchIcon";
import SubmitIcon from "../icon/SubmitIcon";
import CloseIcon from "../icon/CloseIcon";
import SeeIcon from "../icon/SeeIcon";
import NoSeeIcon from "../icon/NoSeeIcon";
import Reload from "../icon/Reload";
import Process from "../icon/Processing";

import { Pagination } from "antd";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import EditOrder from "../icon/EditOrder";
import { routePage } from "../utils/RoutePage";

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      page: 1,
      per_page: 10,
      count: 0,
      orderList: [],
      userid: 0,
      isClick: "",
      status: "",
      search: "",
      usertype: null,
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.getOrderList = this.getOrderList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.clickTransformImage = this.clickTransformImage.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.clickToMove = this.clickToMove.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.clickToSearch = this.clickToSearch.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    let userid = 0;
    if (param) {
      console.log(param);
      if (param.user.type !== 2) {
        userid = param.user.id;
        this.setState({ userid, usertype: param.user.type });
      }
    }
    this.getOrderList(this.state.page, this.state.per_page, this.state.status);
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getOrderList(page, per_page, status, search) {
    this.toggleShow();
    GetOrderListAPI.getOrderList(page, per_page, status, search)
      .then((res) => {
        this.toggleShow();
        var data = res.data.data;
        if (data) {
          var total = data.total;
          let count = Math.ceil(total / per_page);
          data.list.map((item, index) => {
            item.isOpen = [false, false, false];
          });
          this.setState({
            orderList: data.list,
            count,
          });
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  handlePageChange(e, page, pageSize) {
    // 确保 page 和 pageSize 是数字
    const currentPage = parseInt(page, 10);
    const currentPerPage = parseInt(pageSize, 10);

    if (isNaN(currentPage) || isNaN(currentPerPage)) {
      console.error("Invalid page or pageSize");
      return;
    }
    this.setState({ page: currentPage, per_page: currentPerPage }, () => {
      this.getOrderList(currentPage, currentPerPage, this.state.status);
    });
  }

  clickToMove(type) {
    let { isClick, status } = this.state;
    status = type;
    this.setState({ isClick: type, status }, () => {
      this.getOrderList(
        this.state.page,
        this.state.per_page,
        this.state.status
      );
    });
  }

  clickTransformImage(index, InIndex) {
    let { orderList } = this.state;
    orderList[index].isOpen[InIndex] = !orderList[index].isOpen[InIndex];
    this.setState({ orderList });
  }

  selectChange(e, type) {
    switch (type) {
      case "site":
        this.setState({ site: e }, () => {
          this.getOrderList(1, this.state.per_page, this.state.userid, e.label);
        });
        break;
      default:
        break;
    }
  }

  changeSearch(e) {
    this.setState({ search: e.target.value });
  }

  clickToSearch() {
    this.getOrderList(
      1,
      this.state.per_page,
      this.state.userid,
      this.state.site.label === "Please choose site ..."
        ? ""
        : this.state.site.label,
      this.state.states,
      this.state.search
    );
  }

  inputChange(e, type) {
    switch (type) {
      case "select":
        this.setState({ stateValue: e });
        this.setState({ status: e.value }, () => {
          this.getFileList();
        });
        break;
      case "search":
        this.setState({ name: e.target.value });
        break;

      case "email":
        this.setState({ toEmailUser: e.target.value });
        break;
      case "locating":
        this.setState({ locating: e.target.value });
        break;
      case "replace":
        this.setState({ replace: e.target.value });
        break;
      case "subjectLocating":
        this.setState({ subjectLocating: e.target.value });
        break;
      case "subjectReplace":
        this.setState({ subjectReplace: e.target.value });
        break;

      default:
        break;
    }
  }

  render() {
    let { count, page, orderList, userid, isClick, search, status } =
      this.state;
    let param = cookie.load("token");
    let { history } = this.props;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="order-toolbar ">
          {/* <div className="search">
            <input
              type={search || ""}
              placeholder="email or name ..."
              onChange={(e) => this.changeSearch(e)}
              onKeyDown={(e) => e.key === "Enter" && this.clickToSearch()}
            />
          </div> */}
          <div className="statuBar">
            <p className="btn-p" onClick={() => this.clickToMove("processing")}>
              PROCESSING
            </p>
            <p className="btn-p" onClick={() => this.clickToMove("")}>
              ALL
            </p>
            <p className="btn-p" onClick={() => this.clickToMove("pending")}>
              PENDING
            </p>

            <div
              className={`btn ${isClick === "pending" && "move1"} ${isClick === "processing" && "move2"
                }`}
            >
              {status || "ALL"}
            </div>
          </div>
        </div>
        <div className="order-page">
          <div className="head">
            <div className="left">
              <div className="product">PRODUCT</div>
              <div className="sku">SKU</div>
              <div className="price">PRICE</div>
              <div className="name">CUSTOMER</div>
              <div className="description">DESCRIPTION</div>
              <div className="status">STATE</div>
              <div className="time">CREATE TIME</div>
            </div>
            <div className="tool">OPERATION</div>
          </div>
          <div className="body">
            {orderList.length > 0 ? (
              <React.Fragment>
                {orderList.map((item, index) => {
                  return (
                    <React.Fragment>
                      <div className="li" key={index}>
                        <div className={`type ${item.menuname.toLowerCase()}`}>
                          {item.menuname}
                        </div>
                        <div className="left">
                          <div className="product">{item.productname}</div>
                          <div className="sku">
                            <img src={BASE_URL + item.skuicon} alt="" />
                            <div className="sku-p">({item.skuname})</div>
                          </div>
                          <div className="price">${item.total}</div>
                          <div className="name">{item.username}</div>
                          <div className="description">{item.description}</div>
                          <div className="status">
                            <div className={`state-btn ${item.status}`}>
                              {item.status}
                            </div>
                          </div>
                          <div className="time">
                            {moment(item.createtime * 1000).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </div>
                        </div>
                        <div className="tool">
                          <EditOrder
                            size={25}
                            className="icon"
                            color="#285db1"
                            onClick={() =>
                              routePage(history, "EditOrder", item.id)
                            }
                          />
                        </div>
                      </div>
                      <div className="mobile-li">
                        <div className="row">
                          <div
                            className={`type ${item.menuname.toLowerCase()}`}
                          >
                            {item.menuname}
                          </div>
                          <div className="left">
                            <div className="img">
                              <img src={BASE_URL + item.icon} alt="" />
                            </div>
                          </div>
                          <div className="right">
                            <div className="r-row">
                              <div className="title">CUSTOMER</div> <i>:</i>
                              <div className="value">{item.username}</div>
                            </div>
                            <div className="r-row">
                              <div className="title">COMPANY</div> <i>:</i>
                              <div className="value">{item.productname}</div>
                            </div>
                            <div className="r-row">
                              <div className="title">SKU</div> <i>:</i>
                              <div className="value">
                                <div className="sku">
                                  <img src={BASE_URL + item.skuicon} alt="" />
                                  <div className="sku-p">({item.skuname})</div>
                                </div>
                              </div>
                            </div>
                            <div className="r-row">
                              <div className="title">DESCRIPTION</div>
                              <i>:</i>
                              <div className="value">{item.description}</div>
                            </div>
                            <div className="r-row">
                              <div className="title">PRICE</div>
                              <i>:</i>
                              <div className="value">
                                <span>USD {item.total}</span>
                              </div>
                            </div>
                            <div className="r-row">
                              <div className="title">TIME</div>
                              <i>:</i>
                              <div className="value">
                                {moment(item.createtime * 1000).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </div>
                            </div>
                            <div className="r-row">
                              <div className="value">
                                <div className="state">
                                  <div className={`state-btn ${item.status}`}>
                                    {item.status}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="btn">
                            <EditOrder
                              size={25}
                              className="icon"
                              color="#285db1"
                              onClick={() =>
                                routePage(history, "EditOrder", item.id)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            ) : (
              <div className="no-content">
                <NoContent size={200} />
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <Pagination
            size="small"
            total={this.state.count * this.state.per_page}
            pageSize={this.state.per_page}
            current={this.state.page}
            onChange={(page, pageSize) =>
              this.handlePageChange(null, page, pageSize)
            }
            showQuickJumper
            variant="outlined"
            color="standard"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Order;
