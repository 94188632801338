import React, { Component } from "react";
import "../../style/common/popup.scss";

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popState: false,
      popMsg: false,
    };
  }

  alertModalTimeoutInterval = null;

  componentDidMount() {
    window.addEventListener("showAlert", (event) => {
      this.setState({
        popState: true,
        popMsg: event && event.detail,
      });
      if (this.alertModalTimeoutInterval) {
        window.clearTimeout(this.alertModalTimeoutInterval);
      }
      this.alertModalTimeoutInterval = window.setTimeout(() => {
        this.setState({ popState: false, popMsg: "" });
      }, 2000);
      console.log(event);
    });
  }

  componentWillUnmount() {
    if (this.alertModalTimeoutInterval) {
      window.clearTimeout(this.alertModalTimeoutInterval);
    }
  }

  render() {
    const { popMsg, popState } = this.state;
    return (
      <React.Fragment>
        {popState && (
          <div className="pop-box">
            <h4>
              <span></span>
              {popMsg}
            </h4>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Popup;
