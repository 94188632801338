import React, { useEffect, useState, useImperativeHandle } from "react";
import "../../style/common/alertMax.scss";
import TipIcon from "../../icon/TipIcon";
import TipCloseIcon from "../../icon/TipCloseIcon";

const AlertMax = React.forwardRef((props, ref) => {
  const [AlertShow, setAlertShow] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertType, setAlertType] = useState("tip");

  useEffect(() => {
    const handleShowAlert = (event) => {
      setAlertShow(true);
      setAlertTitle(event?.detail?.alertTitle || "");
      setAlertType(event?.detail?.alertType || "");
    };
    window.addEventListener("showAlertMax", handleShowAlert);
    // 清理函数，组件卸载时移除事件监听器
    return () => {
      window.removeEventListener("showAlertMax", handleShowAlert);
    };
  }, []);

  const handleHiddenAlert = () => {
    setAlertShow((prevAlertShow) => !prevAlertShow);
  };

  return (
    <React.Fragment>
      {AlertShow && (
        <div
          className={`alertMax-box ${
            AlertType === "warn" ? "warning" : AlertType === "tip" ? "tip" : ""
          }`}
        >
          <TipIcon
            className="tip-icon"
            size={20}
            color={`${
              AlertType === "warn"
                ? "#720202"
                : AlertType === "tip"
                ? "#027243"
                : ""
            }`}
          />
          <TipCloseIcon
            size={22}
            className="close-icon"
            color={`${
              AlertType === "warn"
                ? "#720202"
                : AlertType === "tip"
                ? "#027243"
                : ""
            }`}
            onClick={handleHiddenAlert}
          />
          <div className="tip-content">
            <p>{AlertTitle}</p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
});

export default AlertMax;
