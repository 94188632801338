import React, { Component } from "react";
import "../style/contact.scss";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";

class Contact extends Component {
  constructor(props) {
    super(props);
  }
  HeaderBarRef = React.createRef();
  FooterBarRef = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar ref={this.HeaderBarRef} {...this.props} />
        <div className="contact-page">
          <div className="title">Contact</div>
          <div className="form">
            <div className="col">
              <input type="text" placeholder="Your Name" />
              <input type="text" placeholder="Your Email" />
              <input type="text" placeholder="Subject" />
              <textarea placeholder="Your Message"></textarea>
              <div className="btn">SEND MESSAGE</div>
            </div>
            <div className="col">
              <p>
                If you'll like to know more about our experience designing and
                delivering cloud solutions, or get advice on your own technology
                challenges get in touch. With dedicated engineers on-hand 24/7,
                we’re set up to become an extension of your team.
              </p>
              <p>
                +44 20 8144 6356 <br />
                info@altostack.io
              </p>
              <p>
                71 Shelton Street <br />
                London WC2H 9JQ England
              </p>
            </div>
          </div>
        </div>
        <FooterBar ref={this.FooterBarRef} {...this.props} />
      </React.Fragment>
    );
  }
}

export default Contact;
