import React from "react";

const OnlinePeople = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="8575"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M547.3 894.8c-15.2-0.2-27.4-12.5-27.6-27.6V728.7c0-6.3 1.9-12.5 5.8-17.4 5.2-6.5 13.2-10.7 22-10.7 8.9 0 16.9 4.1 22 10.7 3.9 4.9 5.8 11.1 5.8 17.4v138.5c-0.2 15.3-12.7 27.6-28 27.6zM830.7 894.8c-15.5 0-28.1-12.6-28.1-28.1V654.5c1.7-14.1 13.7-24.7 27.9-24.7s26.1 10.6 27.8 24.7v212.2c0 7.5-3 14.7-8.4 20-5.3 5.3-12.6 8.2-20.1 8.1h0.9zM736.4 894.8c-15.5 0-28.1-12.6-28.1-28.1V582.2c0-15.4 12.5-27.8 27.8-27.8 15.4 0 27.9 12.5 27.9 27.8v284.6c0 15.3-12.3 27.8-27.6 28zM641.7 894.8c-15.3-0.2-27.6-12.7-27.6-28.1V689.9c0.2-11.2 7.1-21.1 17.4-25.4 10.3-4.2 22.2-2 30.2 5.8 5.2 5.2 8.1 12.2 8.1 19.5v176.9c0 15.5-12.5 28-28 28.1h-0.1zM535.5 131.9c-95.1-14.4-187.3 39.9-220.9 130-33.6 90.1 0.5 191.5 81.8 242.9-137.7 49-229.9 179.1-230.6 325.3 0 13.6 0.8 27.3 2.4 40.8 1.9 13.8 13.7 24.1 27.6 24 8.3-0.5 15.9-4.7 20.7-11.4 5.3-5.8 7.7-13.8 6.5-21.6-1.3-10.8-2-21.7-2-32.5 0.2-160.5 130.3-290.5 290.7-290.7 111.4 5.1 206.1-80.4 212.6-191.7 6.6-111.4-77.6-207.2-188.8-215.1z m108.3 272.5c-29.7 57.6-93.2 89.3-157.1 78.5-62.5-10.6-111.4-59.5-122-122-10.8-63.9 20.9-127.4 78.5-157.1 57.6-29.7 127.7-18.8 173.5 27 45.9 45.9 56.8 116.1 27.1 173.6z"
      fill={color}
      p-id="8576"
    ></path>
  </svg>
);

export default OnlinePeople;
