import React, { Component } from "react";
import "../style/story.scss";
import sec1_bg from "../image/banner-bg.png";
import r1 from "../image/green-dot.png";
import r2 from "../image/blue-half-cycle.png";
import r3 from "../image/service-half-cycle.png";
import r4 from "../image/yellow-triangle.png";
import r5 from "../image/team-bg-triangle.png";
import r6 from "../image/seo-ball-1.png";
import r7 from "../image/seo-ball-2.png";
import r8 from "../image/seo-half-cycle.png";
import r9 from "../image/feature-bg-2.png";
import { routePage } from "../utils/RoutePage";
import cookie from "react-cookies";
import HeaderBar from "./common/HeaderBar";
import sce2 from "../image/banner-img.png";
import Design from "../icon/Design";
import Card from "../icon/Card";
import Code from "../icon/Code";
import Duplicate from "../icon/Duplicate";
import sec4 from "../image/seo-bg.png";
import sec4Img from "../image/marketing.png";
import sec5 from "../image/service-bg.png";
import Window from "../icon/Window";
import Email from "../icon/Email";
import Tool from "../icon/Tool";
import sec6 from "../image/team-bg.png";
import sec5Img from "../image/service.png";
import sec6Img from "../image/team-bg-dots.png";
import FooterBar from "./common/FooterBar";

class Story extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: "https://t.me/stripekyckyc",
    };
    this.route = this.route.bind(this);
    this.contactService = this.contactService.bind(this);
  }

  headerBarRef = React.createRef();
  footerBarRef = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  route() {
    let param = cookie.load("token");
    const { history } = this.props;
    if (param) {
      history.push("/Entrance");
    } else {
      history.push("/Login");
    }
  }

  contactService() {
    const { service } = this.state;
    window.open(service, "_blank");
  }

  render() {
    const { service } = this.state;
    return (
      <div className="story-page">
        <HeaderBar {...this.props} ref={this.headerBarRef} />
        <div className="sec1">
          <img src={sec1_bg} alt="" />
          <div className="sec1-txt">
            <div className="title">
              Take Control of your <br /> Project and Team
            </div>
            <div className="p">
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum <br /> dolore eu fugiat nulla pariatur.
            </div>
            <div className="btn">Explore Us</div>
          </div>
          <div className="r1">
            <img src={r1} alt="" />
          </div>
          <div className="r2">
            <img src={r2} alt="" />
          </div>
          <div className="r3">
            <img src={r3} alt="" />
          </div>
          <div className="r4">
            <img src={r4} alt="" />
          </div>
          <div className="r5">
            <img src={r5} alt="" />
          </div>
          <div className="r6">
            <img src={r6} alt="" />
          </div>
          <div className="r7">
            <img src={r7} alt="" />
          </div>
          <div className="r8">
            <img src={r8} alt="" />
          </div>
          <div className="r9">
            <img src={r9} alt="" />
          </div>
        </div>
        <div className="sec2">
          <img src={sce2} alt="" />
        </div>
        <div id="feature"></div>
        <div className="sec3">
          <div className="title">Awesome Features</div>
          <div className="little-title">
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. <br /> Excepteur sint
            occaecat cupidatat non proident
          </div>
          <div className="row">
            <div className="col">
              <div className="img">
                <Design size={40} color="#fff" />
              </div>
              <div className="txt">
                <p>
                  <b>Awesome Design</b>
                  <br />
                  Consectetur adipiscing elit donec tempus <br />
                  pellentesque dui.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="img">
                <Card size={40} color="#fff" />
              </div>
              <div className="txt">
                <p>
                  <b>Easy Customize</b>
                  <br />
                  Consectetur adipiscing elit donec tempus <br />
                  pellentesque dui.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="img">
                <Code size={40} color="#fff" />
              </div>
              <div className="txt">
                <p>
                  <b>Bug free Code</b>
                  <br />
                  Consectetur adipiscing elit donec tempus <br />
                  pellentesque dui.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="img">
                <Duplicate size={40} color="#fff" />
              </div>
              <div className="txt">
                <p>
                  <b>Organized Layouts</b>
                  <br />
                  Consectetur adipiscing elit donec tempus <br />
                  pellentesque dui.
                </p>
              </div>
            </div>
          </div>
          <div className="r9">
            <img src={r9} alt="" />
          </div>
          <div className="r6">
            <img src={r6} alt="" />
          </div>
        </div>
        <div className="sec4">
          <img src={sec4} alt="" />
          <div className="sec4-txt">
            <div className="left">
              <img src={sec4Img} alt="" />
            </div>
            <div className="right">
              <div className="title">
                A Complete Range <br /> Of SEO Marketing <br /> Services!
              </div>
              <div className="p">
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia. There live the blind texts. Separated
                they live in Bookmarksgrove right at the coast of the Semantics,
                a large language ocean.
              </div>
            </div>
          </div>
          <div className="r4">
            <img src={r4} alt="" />
          </div>
          <div className="r7">
            <img src={r7} alt="" />
          </div>
          <div className="r3">
            <img src={r3} alt="" />
          </div>
        </div>
        <div className="sec5">
          <img src={sec5} alt="" className="sec5-img" />
          <div className="sec5-txt">
            <div className="right">
              <div className="title">
                Powerful Layout <br /> From Top To Bottom
              </div>
              <div className="p">
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live the blind texts. Separated
                they live in Bookmarksgrove right at the coast of the Semantics,
                a large language ocean.
              </div>
              <div className="p">
                <Window size={28} color="#9491ff" className="p-icon" />{" "}
                Responsive on any device
              </div>
              <div className="p">
                <Email size={28} color="#9491ff" className="p-icon" /> Very easy
                to customize
              </div>
              <div className="p">
                <Tool size={28} color="#9491ff" className="p-icon" /> Effective
                support
              </div>
            </div>
            <div className="left">
              <img src={sec5Img} alt="" />
            </div>
          </div>
          <div className="r3">
            <img src={r6} alt="" />
          </div>
          <div className="r7">
            <img src={r6} alt="" />
          </div>
          <div className="r8">
            <img src={r4} alt="" />
          </div>
        </div>
        <div id="pricing"></div>
        <div className="sec6">
          <div className="title">Our Pricing</div>
          <div className="little-title">
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu <br /> fugiat nulla pariatur. Excepteur sint
            occaecat
          </div>
          <div className="row">
            <div className="col">
              <div className="tl1">Free</div>
              <div className="tl2">
                $ <span>00</span>
              </div>
              <div className="tl3">
                Far far away, behind the wordmountains, far from the countries
                Vokalia and
              </div>
              <div className="tl4">Get Started</div>
            </div>
            <div className="col">
              <div className="tl1">Standard</div>
              <div className="tl2">
                $ <span>75</span>
              </div>
              <div className="tl3">
                Far far away, behind the wordmountains, far from the countries
                Vokalia and
              </div>
              <div className="tl4">Buy Now</div>
            </div>
            <div className="col">
              <div className="tl1">Premium</div>
              <div className="tl2">
                $ <span>99</span>
              </div>
              <div className="tl3">
                Far far away, behind the wordmountains, far from the countries
                Vokalia and
              </div>
              <div className="tl4">Buy Now</div>
            </div>
          </div>
          <div className="r4">
            <img src={r4} alt="" />
          </div>
          <div className="r1">
            <img src={r9} alt="" />
          </div>
          <div className="r3">
            <img src={r2} alt="" />
          </div>
        </div>
        <div className="sec7">
          <div className="title">Subscribe to our newsletter</div>
          <div className="little-title">Receive updates, news and deals</div>
          <div className="input-row">
            <div className="input">{service}</div>
            <div className="btn" onClick={this.contactService}>
              SUBSCRIBE
            </div>
          </div>
          <div className="r7">
            <img src={r7} alt="" />
          </div>
          <div className="r4">
            <img src={r2} alt="" />
          </div>
        </div>
        <FooterBar ref={this.footerBarRef} {...this.props} />
      </div>
    );
  }
}

export default Story;
