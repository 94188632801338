import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  BrowserRouter,
  Redirect,
} from "react-router-dom";

import Popup from "./components/common/Popup";
import Loading from "./components/common/Loading";
import queryString from "query-string";
import Login from "./components/Login";
import Entrance from "./components/Entrance";
import "./utils/OrderUtil";
import UserLogin from "./components/UserLogin";
import AlertMax from "./components/common/AlertMax";
import Story from "./components/Story";
import cookie from "react-cookies";
import Contact from "./components/Contact";
import About from "./components/About";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <AlertMax />
          <Popup {...this.props} />
          <Switch>
            <Route path="/About" component={About}></Route>
            <Route path="/Contact" component={Contact}></Route>
            <Route path="/Login" component={UserLogin}></Route>
            <Route path="/Entrance" component={Entrance}></Route>
            <Route exact path="/" component={Story}></Route>
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}
export default App;
