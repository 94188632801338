import React, { Component } from "react";
import CloseIcon from "../icon/CloseIcon";
import "../style/addNews.scss";
import Loading from "./common/Loading";
import Submit from "../icon/SubmitIcon";
import * as SubmitNewsAPI from "../apis/SubmitNewsAPI";
import * as GetNewsAPI from "../apis/GetNewsAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";

import FileIcon from "../icon/FileIcon";
import { upload } from "../utils/UploadFile";
import cookie from "react-cookies";
import { img } from "@douyinfe/semi-ui/lib/es/markdownRender/components";
import { Select } from "antd";
import Edit from "./common/Edit";
import EditorMix from "./common/EditorMix";
import { setAPIAuthorizationToken } from "../apis/API";
import { getId } from "../utils/RoutePage";

class AddNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      title: "",
      logo: null,
      id: 0,
      isClick: true,
      status: 1,
      description: "",
      nickname: "",
    };
    this.inputChange = this.inputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.back = this.back.bind(this);
    this.clickToMove = this.clickToMove.bind(this);
  }

  EditRef = React.createRef();

  componentDidMount() {
    let param = cookie.load("token");
    setAPIAuthorizationToken(param.token);
    let { location } = this.props;
    let id = getId(location, "AddNews");
    if (id) {
      this.toggleShow();
      GetNewsAPI.getNews(id)
        .then((res) => {
          this.toggleShow();
          console.log(res);
          var data = res.data.data;
          if (data) {
            this.setState({
              id: parseInt(id),
              title: data.title,
              description: data.description,
              logo: data.logo,
              status: data.status,
              isClick: data.status > 0 ? true : false,
            });
          }
        })
        .catch((err) => {
          this.toggleShow();
        });
    }
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  async inputChange(e, type) {
    let param = cookie.load("token");
    switch (type) {
      case "title":
        this.setState({ title: e.target.value });
        break;
      case "nickname":
        this.setState({ nickname: e.target.value });
        break;
      case "logo":
        const url = await upload(e, param.token);
        if (url) {
          this.setState({ logo: url });
        }
        break;
      default:
        break;
    }
  }

  submit() {
    let { id, title, logo, status, description, nickname } = this.state;
    if (this.EditRef) {
      description = this.EditRef.current.getValue();
      let param = {
        id,
        title,
        logo,
        description,
        status,
        nickname,
      };
      this.toggleShow();
      SubmitNewsAPI.submitNews(param)
        .then((res) => {
          this.toggleShow();
          var code = res.data.code;
          var msg = res.data.msg;
          console.log(msg);
          if (code === 1) {
            if (id !== 0) {
              window.dispatchEvent(
                new CustomEvent("showAlert", {
                  detail: "Modify user successfully",
                })
              );
              this.back();
            } else {
              window.dispatchEvent(
                new CustomEvent("showAlert", {
                  detail: "Add User Successfully",
                })
              );
              this.back();
            }
            return;
          }
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: msg,
            })
          );
        })
        .catch((err) => {
          this.toggleShow();
        });
    }
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  clickToMove() {
    let { isClick, status } = this.state;
    status = isClick ? 0 : 1;
    this.setState({ isClick: !this.state.isClick, status });
  }

  render() {
    const { title, logo, isClick, description, nickname } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="addNews-page">
          <CloseIcon
            size={30}
            className="icon close"
            color="#5864ff"
            onClick={this.back}
          />
          <div className="row">
            <div className="title">News title :</div>
            <input
              type="text"
              value={title || ""}
              onChange={(e) => this.inputChange(e, "title")}
            />
          </div>
          <div className="row">
            <div className="title">Icon :</div>
            <div className="file">
              <input
                type="file"
                placeholder="image"
                className="file-input"
                onChange={(e) => {
                  this.inputChange(e, "logo");
                }}
              />
              {logo !== null ? (
                <div className="img">
                  <img src={BASE_URL + logo} />
                </div>
              ) : (
                <React.Fragment>
                  <FileIcon size={50} color="#5864ff" />
                  <p className="p">
                    Drop your image here,or browse <br />
                    JPG,PNG and GIF files are allowed
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="row">
            <div className="title">Poster's name :</div>
            <input
              type="text"
              value={nickname || ""}
              onChange={(e) => this.inputChange(e, "nickname")}
            />
          </div>

          <div className="row">
            <div className="title">Description : </div>
            <EditorMix
              {...this.props}
              ref={this.EditRef}
              value={description || ""}
            />
          </div>
          <div className="row">
            <div className="statuBar">
              <p className="btn-p" onClick={this.clickToMove}>
                ON
              </p>
              <p className="btn-p" onClick={this.clickToMove}>
                OFF
              </p>
              <div className={`btn ${isClick ? "move" : ""}`}></div>
            </div>
          </div>
          <div className="footer">
            <Submit
              size={40}
              color="#5864ff"
              className="icon"
              onClick={this.submit}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddNews;
