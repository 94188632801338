import React, { Component } from "react";
import "../style/vip.scss";
// import Pagination from "@material-ui/lab/Pagination";
import AddUserIcon from "../icon/AddUserIcon";
import DeleteIcon from "../icon/DeleteIcon";
import EditIcon from "../icon/EditIcon";
import * as GetVIPlistAPI from "../apis/GetVIPListAPI";
import Loading from "./common/Loading";
import NoContent from "../icon/NoContent";
import * as DeleteVIPAPI from "../apis/DeleteVIPAPI";
import { Pagination } from "antd";
import CountryIcon from "../icon/Country";
import { FlagIcon } from "react-flag-kit";
import AddCategory from "../icon/AddCategory";

import moment from "moment";
import cookie from "react-cookies";
import { setAPIAuthorizationToken } from "../apis/API";
import Item from "antd/es/list/Item";
import AlertBox from "./common/AlertBox";
import VIPIcon from "../icon/VIPIcon";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import { routePage } from "../utils/RoutePage";

class VIP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      page: 1,
      per_page: 10,
      count: 0,
      vipList: [],
      deleteId: "",
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.getVIPlist = this.getVIPlist.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePreviewAlert = this.handlePreviewAlert.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.confirmHandler = this.confirmHandler.bind(this);
  }

  alertRef = React.createRef();

  componentDidMount() {
    let param = cookie.load("token");
    setAPIAuthorizationToken(param.token);
    this.getVIPlist(this.state.page, this.state.per_page);
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getVIPlist(page, per_page) {
    this.toggleShow();
    GetVIPlistAPI.getVIPlist(page, per_page)
      .then((res) => {
        this.toggleShow();
        var data = res.data.data;
        if (data) {
          var total = data.total;
          console.log(data.list);
          let count = Math.ceil(total / per_page);
          this.setState({
            vipList: data.list,
            count,
          });
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  handlePageChange(e, page, pageSize) {
    // 确保 page 和 pageSize 是数字
    const currentPage = parseInt(page, 10);
    const currentPerPage = parseInt(pageSize, 10);

    if (isNaN(currentPage) || isNaN(currentPerPage)) {
      console.error("Invalid page or pageSize");
      return;
    }
    this.setState({ page: currentPage, per_page: currentPerPage }, () => {
      this.getVIPlist(currentPage, currentPerPage);
    });
  }

  handlePreviewAlert(id) {
    this.handleToggle();
    this.setState({ deleteId: id });
  }

  handleToggle() {
    if (this.alertRef) {
      this.alertRef.current.toggleAlertShow();
    }
  }

  confirmHandler() {
    this.toggleShow();
    DeleteVIPAPI.deleteVIP(this.state.deleteId)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        if (code === 1) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Delete category successfully ",
            })
          );
          this.getVIPlist(this.state.page, this.state.per_page);
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  render() {
    const { count, page, vipList } = this.state;
    let { history } = this.props;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <AlertBox
          ref={this.alertRef}
          {...this.props}
          confirmHandler={this.confirmHandler}
          toggleAlertShow={this.handleToggle}
        />
        <div className="vip-toolbar">
          <div className="logo" onClick={() => routePage(history, "AddVIP")}>
            <VIPIcon size={24} color="#fff" className="icon" />
          </div>
        </div>
        <div className="vip-page">
          <div className="head">
            <div className="left">
              <div className="icon"></div>
              <div className="name">NAME</div>
              <div className="price">PRICE</div>
              <div className="type">LEVEL</div>
              {/* <div className="state">STATE</div> */}
              <div className="time">TIME</div>
            </div>
            <div className="tool">OPERATION</div>
          </div>
          <div className="body">
            {vipList.length > 0 ? (
              <React.Fragment>
                {vipList.map((item, index) => {
                  return (
                    <React.Fragment>
                      <div className="li" key={index}>
                        <div className="left">
                          <div className="icon">
                            <div className="img">
                              <img src={BASE_URL + item.icon} alt="" />
                            </div>
                          </div>
                          <div className="name">{item.huiyuanname}</div>
                          <div className="price">{item.price}</div>
                          <div className="type">LV.{item.huiyuandengji}</div>
                          {/* <div
                          className={`state ${item.status > 0 ? "on" : "off"}`}
                        >
                          {item.status > 0 ? "ON" : "OFF"}
                        </div> */}
                          <div className="time">
                            {moment(item.createtime * 1000).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </div>
                        </div>
                        <div className="tool">
                          <EditIcon
                            size={25}
                            className="icon"
                            color="#285db1"
                            onClick={() =>
                              routePage(history, "AddVIP", item.id)
                            }
                          />
                          <DeleteIcon
                            size={25}
                            className="icon"
                            color="#285db1"
                            onClick={() => this.handlePreviewAlert(item.id)}
                          />
                        </div>
                      </div>
                      <div className="mobile-li">
                        <div className="row">
                          <div className="left">
                            <div className="img">
                              <img src={BASE_URL + item.icon} alt="" />
                            </div>
                          </div>
                          <div className="right">
                            <div className="r-row">
                              <div className="title">NAME</div> <i>:</i>
                              <div className="value">{item.huiyuanname}</div>
                            </div>
                            <div className="r-row">
                              <div className="title">PRICE</div>
                              <i>:</i>
                              <div className="value">
                                <span>${item.price}</span>
                              </div>
                            </div>
                            <div className="r-row">
                              <div className="title">LEVEL</div>
                              <i>:</i>
                              <div className="value">
                                LV.{item.huiyuandengji}
                              </div>
                            </div>
                            <div className="r-row">
                              <div className="title">TIME</div>
                              <i>:</i>
                              <div className="value">
                                {moment(item.createtime * 1000).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="btn">
                            <EditIcon
                              size={25}
                              className="icon"
                              color="#285db1"
                              onClick={() =>
                                routePage(history, "AddVIP", item.id)
                              }
                            />
                            <DeleteIcon
                              size={25}
                              className="icon"
                              color="#285db1"
                              onClick={() => this.handlePreviewAlert(item.id)}
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            ) : (
              <div className="no-content">
                <NoContent size={200} />
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <Pagination
            size="small"
            total={this.state.count * this.state.per_page}
            // count={count}
            pageSize={this.state.per_page}
            current={this.state.page}
            onChange={(page, pageSize) =>
              this.handlePageChange(null, page, pageSize)
            }
            showQuickJumper
            variant="outlined"
            color="standard"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default VIP;
